import { Helmet } from 'react-helmet-async';
import { HomepageHeader } from 'components/layout/homepage-header/homepage-header';
import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { NetworkGrid } from './network-grid';

const HomePage = () => {
  return (
    <StyledWrapper>
      <Helmet>
        <title>Api3 Market | Oracles that pay you</title>
      </Helmet>
      <div className="main-content">
        <HomepageHeader title="Oracles that pay you" description="Receive OEV Rewards for your DeFi project" />
        <NetworkGrid />
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;

  > .main-content {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    align-self: stretch;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 40px;
    width: 100%;
    max-width: 640px;

    @media (min-width: ${breakpoints.sm}px) {
      max-width: 800px;
    }

    @media (min-width: ${breakpoints.md}px) {
      gap: 24px;
      max-width: 1008px;
      padding-bottom: 64px;
    }

    @media (min-width: ${breakpoints.lg}px) {
      max-width: 1332px;
    }
  }
`;

export default HomePage;
