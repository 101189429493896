import { footerLinks } from 'constants/footer-links';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { useWindowDimensions } from 'hooks';
import chunk from 'lodash/chunk';
import { Button } from 'components/shared';

export const Footer = () => {
  const { width } = useWindowDimensions();

  const linksPerLine = width < 500 ? 4 : 8;
  const chunkedLinks = chunk(footerLinks, linksPerLine);

  return (
    <StyledWrapper>
      {chunkedLinks.map((links, index) => (
        <StyledFooterRow key={index}>
          {links.map((link) => (
            <Button
              variant="menu-link-secondary"
              href={link.path}
              key={link.path}
              className="footer-link"
              iconEnd={null}
              size="x-small"
              md={{ size: 'small' }}
            >
              {link.label}
            </Button>
          ))}
        </StyledFooterRow>
      ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-width: 360px;
  min-height: var(--footer-height-xs);
  padding: 40px 24px 24px 24px;

  @media (max-width: ${breakpoints.xs}px) {
    min-width: unset;
  }

  @media (min-width: ${breakpoints.sm}px) {
    min-height: var(--footer-height-sm);
  }

  @media (min-width: ${breakpoints.md}px) {
    min-height: var(--footer-height-md);
    justify-content: end;
    padding: 40px 40px 32px 40px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    min-height: var(--footer-height-lg);
  }
`;

const StyledFooterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }

  & > .footer-link {
    position: relative;
    padding: 0 5px !important;

    @media (min-width: ${breakpoints.sm}px) {
      padding: 0 12px !important;
    }

    @media (min-width: ${breakpoints.lg}px) {
      padding: 0 16px !important;
    }

    &:first-of-type {
      padding-left: 0 !important;
    }

    &:after {
      content: '';
      border: 1px solid var(--color-neutral-gray7);
      border-width: 2px 2px 0 0;
      position: absolute;
      right: -1px;
      top: 20%;
      height: 70%;
    }

    &:last-child:after {
      border: none;
    }
  }
`;
