import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { Button } from 'components/shared';
import { DiscordIcon } from 'components/shared/icons';
import { links } from 'constants/links';

export const DiscordButton = () => (
  <StyledButton
    variant="link"
    href={links.API3_DISCORD}
    size="small"
    md={{ size: 'large' }}
    iconEnd={null}
    iconStart={<DiscordIcon />}
  >
    Join our Discord community
  </StyledButton>
);

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 328px;
  height: 56px !important;
  padding: 16px;
  background:
    linear-gradient(0deg, rgba(12, 17, 67, 0.2) 0%, rgba(12, 17, 67, 0.2) 100%),
    linear-gradient(0deg, rgba(5, 8, 36, 0.4) 0%, rgba(5, 8, 36, 0.4) 100%),
    radial-gradient(91.49% 80.25% at 50.09% 11.9%, rgba(45, 55, 163, 0.7) 0%, rgba(9, 13, 56, 0) 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  outline: none; // Remove yellow border on focus
  color: var(--color-dark-blue-10) !important;
  transition: border 0s;

  & svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  a {
    padding-top: 2px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    max-width: unset;
    width: 428px;
  }

  @media (min-width: ${breakpoints.md}px) {
    width: 344px;
    height: 72px;
    border-radius: 16px;
    height: 72px !important;
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 428px;
    height: 88px !important;
  }

  &:hover:not(.disabled) {
    border: 1px solid var(--color-dark-blue-400);
    background:
      linear-gradient(0deg, rgba(12, 17, 67, 0.2) 0%, rgba(12, 17, 67, 0.2) 100%),
      linear-gradient(0deg, rgba(5, 8, 36, 0.4) 0%, rgba(5, 8, 36, 0.4) 100%),
      radial-gradient(91.49% 80.25% at 50.09% 11.9%, rgba(45, 55, 163, 0.7) 0%, rgba(9, 13, 56, 0) 100%);
    color: var(--color-blue-200) !important;
  }

  &:focus:not(.disabled) {
    border: 1px solid var(--color-dark-blue-500);
    background: var(--color-dark-blue-900);
    color: var(--color-blue-200) !important;
  }

  &.disabled {
    background: rgba(5, 8, 36, 0.8);
    color: var(--color-gray-700) !important;
  }
`;
