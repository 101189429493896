import * as Sentry from '@sentry/react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Sidetab } from '@typeform/embed-react';
import { ShowBreakpoints } from 'components/shared/show-breakpoints/show-breakpoints';
import { breakpoints } from 'styles/theme';
import { NavigationHistoryContextProvider } from 'hooks/use-navigation-history';
import { useClearToasts, useFeedbackForm, useLogosPreLoader } from 'hooks';
import { useLoader } from 'hooks/use-loader';
import { useValidatePageParams } from 'hooks/use-validate-page-params';
import { SwitchNetworkModal } from 'components/sign-in/switch-network-modal';
import { useGeneralStore } from 'stores';
import { useCheckIfDifferentChain, useDisconnectOnPageClose, useInitializeProvider } from 'hooks/chain-data';
import { useShowMaintenanceBanner } from 'components/maintenance/use-show-maintenance-banner';
import { useCheckSanctionedAddress } from 'hooks/chain-data/use-check-sanctioned-address';
import Header from './header/header';
import { Footer } from './footer/footer';
import { NotificationBanner } from './notification-banner';
import { ErrorFallback } from './error-fallback';
import { ErrorReportingNotice } from './error-reporting-notice';
import MobileMenu from './mobile-menu';
import { RequestGrantBanner } from './request-grant-banner';
import { ScrollableContent } from './scrollable-content';

export const Layout = () => {
  const { chainSwitchStatus, isOnDifferentChain } = useGeneralStore();
  const location = useLocation();
  useInitializeProvider();
  useValidatePageParams();
  useCheckIfDifferentChain();
  useCheckSanctionedAddress();
  useShowMaintenanceBanner();
  useFeedbackForm();
  useClearToasts();
  useLogosPreLoader();
  useDisconnectOnPageClose();

  const { hidePageContent } = useLoader();
  const hasSearchParam = location.search.split(/[?&=]/).includes('search');
  const isOnChainPage = location.pathname.match(/^\/[a-z\-]+$/) && !hasSearchParam;
  const isOnPurchasePage = location.pathname.match(/\/activate(\?.*)?$/);
  const hideAppContent = chainSwitchStatus === 'pending' || isOnDifferentChain;

  if (hidePageContent) {
    return null;
  }

  const BackgroundImage = isOnPurchasePage ? PurchasePageBackgroundImage : 'div';

  return (
    <NavigationHistoryContextProvider>
      <PageWrapper>
        <BackgroundImage>
          {process.env.NODE_ENV === 'development' && !localStorage.getItem('HIDE_BREAKPOINTS') && breakpoints && (
            <ShowBreakpoints />
          )}
          <Header />
          <ScrollableContent>
            <NotificationBanner />
            <ErrorReportingNotice />
            <MobileMenu />
            <RequestGrantBanner isOnChainPage={!!isOnChainPage} />
            <>
              <ContentWrapper id="layout">
                <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                  {!hideAppContent && <Outlet />}
                  <SwitchNetworkModal />
                  {/* TypeForm button for the users' feedback */}
                  <Sidetab
                    id="B50AzvNz"
                    buttonText="Feedback"
                    buttonTextSize={12}
                    buttonTextColor="#fff"
                    buttonWidth={40}
                    buttonHeight={116}
                    top={isOnChainPage ? '130px' : '0'}
                    buttonAlign={isOnChainPage ? 'bottom' : 'center'}
                    customIcon={`${window.location.origin}/images/feedback.svg`}
                  />
                </Sentry.ErrorBoundary>
              </ContentWrapper>
              <Footer />
            </>
          </ScrollableContent>
        </BackgroundImage>
      </PageWrapper>
    </NavigationHistoryContextProvider>
  );
};

const PageWrapper = styled.div`
  background:
    radial-gradient(
      376.7% 96.63% at 50% -9.17%,
      rgba(45, 55, 163, 0.7) 0%,
      rgba(9, 13, 56, 0.7) 45.09%,
      rgba(5, 8, 36, 0.7) 100%
    ),
    linear-gradient(0deg, #030412 0%, #030412 100%), #d9d9d9;
  background-color: #04071f;
  background-repeat: no-repeat;
  background-size: 100% 3613px;

  @media (min-width: ${breakpoints.sm}px) {
    background:
      radial-gradient(
        221.2% 109.17% at 50% -9.17%,
        rgba(45, 55, 163, 0.7) 0%,
        rgba(9, 13, 56, 0.7) 45.09%,
        rgba(5, 8, 36, 0.7) 100%
      ),
      linear-gradient(0deg, #030412 0%, #030412 100%), #d9d9d9;
    background-color: #05071f;
    background-repeat: no-repeat;
    background-size: 100% 3000px;
  }

  @media (min-width: ${breakpoints.md}px) {
    background:
      radial-gradient(
        226% 161.43% at 50% -9.17%,
        rgba(45, 55, 163, 0.7) 0%,
        rgba(9, 13, 56, 0.7) 45.09%,
        rgba(5, 8, 36, 0.7) 100%
      ),
      linear-gradient(0deg, #030412 0%, #030412 1000px), #d9d9d9;
    background-color: #050826;
    background-repeat: no-repeat;
    background-size: 100% 2643px;
  }
`;

const PurchasePageBackgroundImage = styled.div`
  background-image: url('/images/purchase/bg-xs.svg');
  background-position: bottom -300px left -260px;
  background-repeat: no-repeat;

  @media (min-width: ${breakpoints.sm}px) {
    background-position: bottom -410px left -150px;
    background-image: url('/images/purchase/bg-sm.svg');
  }

  @media (min-width: ${breakpoints.md}px) {
    background-position: bottom -390px right -550px;
    background-image: url('/images/purchase/bg-md.svg');
  }

  @media (min-width: 1450px) {
    background-position: bottom -390px left -65px; // Sticking the background to the left side of the screen from 1450px to 1600px
  }

  @media (min-width: ${breakpoints.lg}px) {
    background-position: top -300px right -1970px;
    background-image: url('/images/purchase/bg-lg.svg');
    @media (min-height: 1220px) {
      background-position: bottom -580px right -1970px;
    }
  }

  @media (min-width: 1750px) {
    background-position: top -300px left -75px; // Sticking the background to the left side of the screen from 1450px to 1600px
    @media (min-height: 1220px) {
      background-position: bottom -580px left -75px;
    }
  }
`;

const ContentWrapper = styled.main`
  padding: 32px 16px 0 16px;
  min-height: calc(100vh - var(--header-height-xs) - var(--footer-height-xs));
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.sm}px) {
    padding: 32px 64px 0 64px;
    min-height: calc(100vh - var(--header-height-sm) - var(--footer-height-sm));
  }

  @media (min-width: ${breakpoints.md}px) {
    padding: 40px 96px 0 96px;
    min-height: calc(100vh - var(--header-height-md) - var(--footer-height-md));
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 40px 134px 0 134px;
    min-height: calc(100vh - var(--header-height-lg) - var(--footer-height-lg));
  }
`;
