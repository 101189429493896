import { MouseEvent, Suspense } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGeneralStore, MenuState } from 'stores';
import { breakpoints } from 'styles/theme';
import SignIn from 'components/sign-in/sign-in';
import { Button } from 'components/shared';
import { links } from 'constants/links';
import { DocsIcon } from 'components/shared/icons';
import clsx from 'clsx';
import styled from 'styled-components';
import { useDetectPageScroll } from 'hooks';
import { SupportDropdown } from './support-dropdown';

const Header = () => {
  const { pathname } = useLocation();

  const { openSidebar } = useGeneralStore();
  const { pageOffset, pageScrollDirection } = useDetectPageScroll();

  const handleSidebarOpen = (type: MenuState, event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    event?.stopPropagation();

    openSidebar(type);
  };

  const logoLinkProps = pathname === '/' ? { reloadDocument: true } : {};

  return (
    <Container
      id="header-main"
      className={clsx({
        scrolled: pageOffset > 0,
        [`scroll-direction-${pageScrollDirection}`]: pageOffset > 0,
      })}
    >
      <div className="header-container-inner">
        <Suspense>
          <Link to="/" {...logoLinkProps} data-testid="api3-market-logo">
            <img src="/images/logo/secondary-dark-mobile.svg" alt="Api3 Market logo" className="header-logo-mobile" />
            <img src="/images/logo/primary-dark-desktop.svg" alt="Api3 Market logo" className="header-logo-desktop" />
            <span className="sr-only">Home</span>
          </Link>
        </Suspense>

        <div className="header-main-section" data-testid="header-main-section">
          <Button
            variant="menu-link-secondary"
            size="medium"
            href={links.DOCS_DAPPS}
            iconStart={<DocsIcon />}
            iconEnd={null}
          >
            <span className="docs-title">Docs</span>
          </Button>
          <SupportDropdown onMobileClick={(event) => handleSidebarOpen('opened-support', event)} />
          <SignIn onMobileClick={(event) => handleSidebarOpen('opened-wallet', event)} />
        </div>
      </div>
    </Container>
  );
};

export const Container = styled.header`
  position: sticky;
  display: flex;
  align-items: center;
  z-index: var(--z-index-header);
  top: 0;
  left: 0;
  right: 0;
  transition: all var(--default-transition-duration) ease-in-out;
  height: var(--header-height-xs);
  padding: 8px 16px;

  &.scrolled {
    &.scroll-direction-down {
      top: calc(var(--header-height-xs) * -1);
    }

    &.scroll-direction-up {
      top: 0;
    }
  }

  &:not(.scrolled) {
    transition: all var(--slow-transition-duration);
  }

  .header-container-inner {
    display: flex;
    align-items: center;
    gap: 0px;
    width: 100%;

    .header-logo-mobile {
      display: block;
    }

    .header-logo-desktop {
      display: none;
    }

    .header-main-section {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
    }
  }

  .docs-title {
    display: none;
  }

  @media (min-width: ${breakpoints.sm}px) {
    height: var(--header-height-sm);

    &.scrolled.scroll-direction-down {
      top: calc(var(--header-height-sm) * -1);
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    height: var(--header-height-md);
    padding: 24px 24px 24px 40px;

    &.scrolled.scroll-direction-down {
      top: calc(var(--header-height-md) * -1);
    }

    .header-container-inner {
      .header-logo-mobile {
        display: none;
      }

      .header-logo-desktop {
        display: block;
      }

      .header-main-section {
        gap: 40px;
      }
    }

    .docs-title {
      display: inline-block;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    height: var(--header-height-lg);

    &.scrolled.scroll-direction-down {
      top: calc(var(--header-height-lg) * -1);
    }
  }
`;

export default Header;
