import { FocusEvent, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { breakpoints } from 'styles/theme';
import { useSelect } from 'downshift';
import { isNullish } from 'utils/utils';
import { fonts } from 'styles/fonts';
import { CrossIcon, EnterIcon, SearchIcon } from '../icons';
import DatafeedLogos from '../datafeed/datafeed-logos';
import { DapiName } from '../datafeed/dapi-name';

export interface IProps {
  className?: string;
  dropdownOptions?: string[];
  parentFocused?: boolean;
  placeholder: string;
  userInput: string;
  searchResultsCount?: number;
  searchValue?: string;
  itemType?: 'dapi' | 'text';
  footer?: ReactNode;
  simpleSearch?: boolean;
  onSearchChange: (newValue: string) => void;
  onSearchSubmit?: (newValue: string) => void;
  onSearchClear: () => void;
  onDropdownOptionClick?: (item: string) => void;
}

const DROPDOWN_MAX_ITEMS = 5;

export const SearchBox = (props: IProps) => {
  const {
    className,
    dropdownOptions,
    parentFocused = false,
    placeholder,
    userInput,
    footer,
    searchResultsCount,
    searchValue = '',
    itemType = 'text',
    simpleSearch = false,
    onSearchChange,
    onSearchSubmit,
    onSearchClear,
    onDropdownOptionClick,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const searchWithDropdownActive = !!userInput && typeof searchResultsCount === 'number';
  const exactMatch =
    dropdownOptions && dropdownOptions.length === 1 && dropdownOptions[0].toLowerCase() === userInput.toLowerCase();
  const dropdownOptionsWithSearchResults =
    searchWithDropdownActive && dropdownOptions && !exactMatch ? [userInput, ...dropdownOptions] : dropdownOptions;

  // Handle focus
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(event.type === 'focus');
  };

  const inputMatchesDropdownOption = (value = ''): string | false =>
    dropdownOptions?.find((d) => d.toLocaleLowerCase() === value.toLocaleLowerCase()) || false;

  const handleChange = (value: string) => {
    onSearchChange(value);
    setHighlightedIndex(-1);
  };

  const handleSubmit = (value: string) => {
    onSearchSubmit?.(inputMatchesDropdownOption(value) || value);
    // Lose focus after submit. Needed on mobile to make the keyboard disappear
    inputRef.current?.blur();
  };

  // Handle highlighted dropdown item
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  useEffect(() => {
    // Reset on input change
    setHighlightedIndex(searchWithDropdownActive ? 0 : -1);
  }, [searchWithDropdownActive, userInput]);

  // Handle dropdown
  const { isOpen, selectItem, getItemProps, getMenuProps, getToggleButtonProps } = useSelect({
    items: dropdownOptionsWithSearchResults || [],
    onSelectedItemChange({ selectedItem, type }) {
      // Clear selection at all times, so that user can click it again if needed
      selectItem(null);
      if (!isNullish(selectedItem) && type === useSelect.stateChangeTypes.ItemClick) {
        onDropdownOptionClick?.(selectedItem);
        inputRef.current?.blur();
      }
    },
    stateReducer: (_, { changes }) => {
      setHighlightedIndex(changes.highlightedIndex ?? -1);
      return changes;
    },
    highlightedIndex,
  });

  return (
    <Container className={className}>
      {/* The search-box-inner class might be used across Toolboxes to customize the max-width of the Search box */}
      <StyledSearchBoxInner
        className={clsx('search-box-inner', {
          focused: parentFocused || !!userInput || isFocused || !!searchValue,
          'search-active': !!searchValue,
          'item-selected': !!inputMatchesDropdownOption(userInput),
          'dropdown-open': isOpen && searchWithDropdownActive,
        })}
        {...getToggleButtonProps({ 'aria-labelledby': undefined })}
        onFocus={handleFocus}
        onBlur={(e) => {
          getToggleButtonProps().onBlur?.(e);
          handleFocus(e as FocusEvent<HTMLInputElement>);
        }}
        onKeyDown={(e) => {
          if (simpleSearch) {
            return;
          }

          getToggleButtonProps().onKeyDown?.(e); // Arrow keys navigation and more

          if (e.key === 'Enter') {
            if (userInput === '') {
              onSearchClear();
              return;
            }

            const submitValue = dropdownOptionsWithSearchResults?.[highlightedIndex] || userInput;
            handleChange(submitValue);
            handleSubmit?.(submitValue); // In case of a dropdown selection, we want to also submit the search
          }
        }}
      >
        <SearchIcon className="search-box-icon" />
        {itemType === 'dapi' && inputMatchesDropdownOption(userInput) && (
          <DatafeedLogos size="sm" md={{ size: 'sm' }} dapiName={userInput} light />
        )}
        <input
          aria-label="Search box"
          id="search-box-input"
          className="search-box-input"
          type="text"
          placeholder={placeholder}
          value={userInput}
          onChange={(event) => handleChange(event.target.value)}
          autoComplete="off"
          ref={inputRef}
        />
        <button
          className={clsx('search-box-clear-button', { 'clear-button-active': userInput })}
          type="button"
          onClick={onSearchClear}
          onKeyDown={(e) => {
            if (e.key === ' ') {
              onSearchClear();
            }
          }}
          tabIndex={userInput ? 0 : -1}
        >
          <CrossIcon strokeWidth={1.5} />
          <span className="sr-only">Clear search</span>
        </button>
      </StyledSearchBoxInner>
      <DropdownOptions className={clsx({ open: isOpen && searchWithDropdownActive })}>
        <ul {...getMenuProps({ 'aria-labelledby': undefined })} tabIndex={-1} data-testid="search-suggestions">
          {isOpen &&
            searchWithDropdownActive &&
            dropdownOptionsWithSearchResults
              ?.slice(0, DROPDOWN_MAX_ITEMS + (searchWithDropdownActive ? 1 : 0))
              .map((item, index) => {
                const isHovered = highlightedIndex === index;

                if (searchWithDropdownActive && index === 0 && !exactMatch) {
                  return (
                    <li
                      className={clsx('search-results-row', { 'current-item': highlightedIndex < 1 })}
                      key={index}
                      {...getItemProps({ item, index })}
                    >
                      <SearchIcon />
                      <span className="no-text-transform">
                        {item}{' '}
                        <span className="search-results-count">
                          -{' '}
                          {searchResultsCount === 1
                            ? `${searchResultsCount} Search Result`
                            : `${searchResultsCount} Search Results`}
                        </span>
                      </span>
                      <div className="divider" />
                    </li>
                  );
                }

                return (
                  <li className={clsx({ 'current-item': isHovered })} key={index} {...getItemProps({ item, index })}>
                    {itemType === 'dapi' && <DatafeedLogos size="sm" dapiName={item} light />}
                    <span className="no-text-transform">
                      {itemType === 'dapi' ? (
                        <DapiName name={item} color="dark-blue" className="dapi-suggestion" />
                      ) : (
                        item
                      )}
                    </span>
                    {exactMatch && (
                      <div className="select-action">
                        <span className="select-title">Select</span>
                        <div className="enter-icon">
                          <EnterIcon />
                        </div>
                      </div>
                    )}
                    <div className="divider" />
                  </li>
                );
              })}
          {footer}
        </ul>
      </DropdownOptions>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin-top: 0;
  margin-left: 0;
  width: 100%;
`;

const StyledSearchBoxInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 32px;
  background-color: var(--color-dark-blue-700);
  box-sizing: border-box;
  transition: background-color var(--default-transition-duration);
  padding: 14px 16px;
  gap: 10px;
  height: 48px;

  .search-box-icon {
    color: var(--color-blue-25);
    position: relative;
    min-height: 16px;
    min-width: 16px;
  }

  .search-box-input,
  .search-box-input::placeholder {
    color: var(--color-dark-blue-25);
    ${fonts.body[12]}
    padding: 0;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .search-box-icon {
    width: 18px;
    height: 18px;
  }

  &.dropdown-open {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &.focused {
    transition: background-color var(--default-transition-duration);
    border: none;
    background-color: var(--color-blue-25);

    .search-box-icon {
      color: var(--color-blue-900);
    }

    .search-box-clear-button {
      color: var(--color-dark-blue-300);
    }

    .search-box-input::placeholder,
    .search-box-input {
      color: var(--color-dark-blue-900);
      caret-color: var(--color-dark-blue-900);
    }
  }

  .search-box-clear-button {
    display: none;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: default;
    color: var(--color-blue-25);
    opacity: 0;
    transition: inherit;

    svg {
      width: 24px;
      height: 24px;
    }

    &.clear-button-active {
      display: flex;
      opacity: 1;
      cursor: pointer;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    height: 64px;

    .search-box-icon {
      min-height: 27px;
      min-width: 27px;
    }

    .search-box-input,
    .search-box-input::placeholder {
      ${fonts.body[6]}
    }

    .search-box-clear-button {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

const DropdownOptions = styled.div`
  &:not(.open) {
    display: none;
  }

  ul {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: var(--z-index-dropdown);
    border-radius: 0 0 8px 8px;

    &:empty {
      padding: 0;
    }
  }

  li {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 28px;
    gap: 12px;
    cursor: pointer;
    white-space: normal;
    box-sizing: border-box;
    background-color: #dfe8ff;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      ${fonts.body[15]}
      color: var(--color-dark-blue-900);
      text-align: left;

      .dapi-suggestion {
        ${fonts.body[12]}
      }
    }

    .select-action {
      position: absolute;
      right: 16px;
      display: flex;
    }

    .select-title {
      color: var(--color-dark-blue-900);
      ${fonts.overline[2]}
      display: none;
    }

    .enter-icon {
      margin-left: 10px;
      padding: 0px 6px;
      color: var(--color-dark-blue-300);
      background-color: var(--color-blue-25);
      border-radius: 2px;
    }

    > div.divider {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 1px;
      background-color: var(--color-dark-blue-10);
    }

    &:last-child {
      padding-bottom: 12px;
      border-radius: 0 0 8px 8px;

      > div.divider {
        display: none;
      }
    }

    &.current-item {
      background-color: var(--color-base-light);
      color: var(--color-dark-blue-900);
    }

    &.search-results-row {
      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        color: var(--color-dark-blue-300);
      }
    }
  }

  @media (min-width: ${breakpoints.xs}px) {
    li {
      .select-action {
        right: 32px;
      }
      .select-title {
        display: block;
      }
    }
  }
`;
