import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { api3Contracts } from '@api3/dapi-management';
import { DEFAULT_PROXY_DAPP_ID } from 'constants/constants';
import { usePageParams } from 'hooks';
import { isDeprecatedDataFeed } from 'utils/dapis';
import { useChain } from 'hooks/chain-data';
import { VerificationStatus } from 'types';
import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { walletMessages } from 'constants/walletMessages';
import { computeProxyAddress, getDappByAlias } from 'utils/contracts';
import { breakpoints } from 'styles/theme';
import { IntegratePageHeader } from './integrate-page-header';
import { IntegratePageContent } from './integrate-page-content';
import { deployProxy } from './utils/deploy-proxy';
import { ProxySelector } from './components/proxy-selector';

const getDappId = (dappAlias: string | null, chainId: string) => {
  if (!dappAlias) return DEFAULT_PROXY_DAPP_ID;

  const dapp = getDappByAlias(dappAlias);
  return dapp ? api3Contracts.unsafeComputeDappId(dappAlias, chainId) : DEFAULT_PROXY_DAPP_ID;
};

const IntegratePage = () => {
  const navigate = useNavigate();
  const { chainAlias, dapiName = '' } = usePageParams();
  const [searchParams] = useSearchParams();
  const dappAlias = searchParams.get('dappAlias');
  const { setErrorBanner } = useNotificationBannerContext();
  const { chain, provider, signer } = useChain(chainAlias);
  const [proxy, setProxy] = useState<string>();
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>('not-requested');
  const [isDeploying, setIsDeploying] = useState(false);

  useEffect(() => {
    if (!chain || !provider || dapiName === '') return;

    if (isDeprecatedDataFeed(chain.id, dapiName)) {
      navigate('/404', { replace: true });
      return;
    }

    const setProxyAddress = async () => {
      const dappId = getDappId(dappAlias, chain.id);

      if (dappAlias !== null && dappId === DEFAULT_PROXY_DAPP_ID) {
        // User selected "with OEV rewards" option but no dApp is selected yet
        setProxy(undefined);
        setVerificationStatus('not-requested');
        return;
      }

      setVerificationStatus('verifying');

      const { success, deployed, proxyAddress } = await computeProxyAddress(chain.id, dapiName, dappId, provider);

      if (!success) {
        setErrorBanner({ message: 'Failed to verify the proxy', secondaryMessage: 'Try again later' });
        setVerificationStatus('error');
        return;
      }

      setVerificationStatus(deployed ? 'verified' : 'not-verified');
      setProxy(proxyAddress);
    };

    setProxyAddress();
  }, [dapiName, dappAlias, chain, provider]);

  const handleDeployProxy = async () => {
    if (!chain || !provider || !signer || dapiName === '') return;

    setIsDeploying(true);
    const dappId = getDappId(dappAlias, chain.id);
    const { error, proxyAddress } = await deployProxy(chain.id, dapiName, provider, signer, dappId);
    setIsDeploying(false);

    if (error) {
      // Something went wrong, and it's not a user rejection
      setErrorBanner({
        message: walletMessages.TX_GENERIC_ERROR_MESSAGE,
        secondaryMessage: walletMessages.TX_GENERIC_ERROR_RELOAD,
      });
    } else {
      setProxy(proxyAddress);
      setVerificationStatus('verified');
    }
  };

  return (
    <div>
      <Helmet>
        <title>Api3 Market | Integrate data feed</title>
      </Helmet>
      <Container className="integrate-page">
        <IntegratePageHeader />
        <ProxySelector />
        <IntegratePageContent
          proxyAddress={proxy}
          verificationStatus={verificationStatus}
          deploying={isDeploying}
          deployProxy={handleDeployProxy}
        />
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 814px;
  min-width: 200px;
  margin: 8px auto 64px auto;

  @media (min-width: ${breakpoints.md}px) {
    margin-top: 0;
  }
`;

export default IntegratePage;
