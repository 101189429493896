import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { api3Contracts } from '@api3/dapi-management';
import Skeleton from 'components/shared/skeleton/skeleton';
import { allChainsById, isDeprecatedDataFeed } from 'utils/dapis';
import { useConnectedAccount, useGetUserBalance } from 'hooks/chain-data';
import { formatBaseUnits } from 'utils/utils';
import { fonts } from 'styles/fonts';
import { PriceBox } from './price-box';
import { ActivateDataFeedButtons } from './activate-data-feed-buttons';
import { OrderDetails } from './order-details';
import { TransactionSummary } from './transaction-summary';
import { useActivateTransaction } from './hooks/use-activate-transaction';
import { usePurchasableSubscriptions } from './hooks/use-purchasable-subscriptions';

interface Props {
  chain: api3Contracts.Chain;
  dapiName: string;
}

export const ActivateDataFeedSection = ({ chain, dapiName }: Props) => {
  const { selectedSubscriptionIndex, subscriptions, setSelectedSubscription, pricingMerkleTreeRoot, isError } =
    usePurchasableSubscriptions(chain, dapiName);

  const selectedSubscription = subscriptions?.[selectedSubscriptionIndex];

  const { tx, txStatus, activate } = useActivateTransaction(
    chain,
    dapiName,
    pricingMerkleTreeRoot,
    selectedSubscription
  );

  const { userBalance } = useGetUserBalance();
  const { isConnected } = useConnectedAccount();

  const fundsToSend = selectedSubscription?.discountedPrice || selectedSubscription?.price;
  const insufficientFunds = fundsToSend !== undefined && (userBalance === 0n || userBalance < BigInt(fundsToSend));

  return (
    <Container>
      <h1>Activate Data Feed</h1>
      <div className="main-section">
        <OrderDetails
          chain={chain}
          dapiName={dapiName}
          subscription={selectedSubscription}
          subscriptionOptions={subscriptions}
          onSubscriptionChange={setSelectedSubscription}
          txInProgress={txStatus === 'pending' || txStatus === 'success'}
          dataLoadingError={isError}
        />
        {tx ? (
          <TransactionSummary tx={tx} chain={chain} dapiName={dapiName} txStatus={txStatus} />
        ) : selectedSubscription ? (
          <PriceBox subscription={selectedSubscription} chain={chain} />
        ) : (
          <Skeleton height="50px" width="100%" className="price-skeleton" enableAnimation={!isError} />
        )}
        <ActivateDataFeedButtons
          dapiName={dapiName}
          chain={chain}
          onActivate={activate}
          txStatus={txStatus}
          disabled={
            !selectedSubscription ||
            txStatus === 'preparing' ||
            isDeprecatedDataFeed(chain.id, dapiName) ||
            insufficientFunds
          }
        />
        {isConnected && insufficientFunds && !tx && (
          <div className="insufficient-funds-message" data-testid="insufficient-funds">
            Insufficient funds. Wallet balance: {formatBaseUnits(userBalance)} {allChainsById[chain.id]?.symbol || ''}
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 468px;
  height: fit-content;
  padding: 12px 12px 24px 12px;
  border-radius: 16px;
  margin-top: -8px;
  margin-bottom: 40px;
  border: 1px solid var(--color-dark-blue-200);
  background-color: var(--color-dark-blue-700);

  h1 {
    font-size: 12px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.5px;
    color: var(--color-dark-blue-500);
    padding: 16px;
    margin: 0;
    border-radius: 8px 8px 4px 4px;
    border: 1px solid var(--color-dark-blue-500);
    background-color: var(--color-blue-10);
  }

  .main-section {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
  }

  .price-skeleton {
    margin-top: 24px;
  }

  .insufficient-funds-message {
    margin-top: 8px;
    ${fonts.body[15]}
    text-align: center;
    color: var(--color-action-error-500);
  }

  @media (min-width: ${breakpoints.sm}px) {
    max-width: 640px;
    padding: 24px 24px 40px 24px;

    h1 {
      ${fonts.heading[10]}
      letter-spacing: 0.16px;
    }

    .insufficient-funds-message {
      ${fonts.body[12]}
      margin-top: 16px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    max-width: 612px;
    padding: 16px 16px 40px 16px;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 24px;

    h1 {
      padding: 24px 40px;
    }

    .main-section {
      padding: 24px 40px 0 40px;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    max-width: 678px;
    padding: 20px 20px 48px 20px;
    margin-right: 64px;
  }
`;
