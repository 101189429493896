import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { Button } from 'components/shared';
import { ExternalLinkIcon } from 'components/shared/icons';
import ConnectButton from 'components/sign-in/connect-button';
import { createExplorerUrl } from 'utils/utils';
import { ChainLogo } from '@api3/logos';
import { useChain, useConnectedAccount } from 'hooks/chain-data';
import { usePageParams } from 'hooks';
import { VerificationStatus } from 'types';
import { fonts } from 'styles/fonts';
import { VerifiedProxyAddress } from './verified-proxy-address';

interface Props {
  proxyAddress?: string;
  verificationStatus: VerificationStatus;
  deploying?: boolean;
  deployProxy?: () => Promise<void>;
}

export const ProxyParamsBox = ({ proxyAddress, verificationStatus, deploying, deployProxy }: Props) => {
  const { chainAlias } = usePageParams();
  const { isConnected } = useConnectedAccount();
  const { chain, chainId = '', chainName } = useChain(chainAlias);
  const explorerUrl = chain?.explorer.browserUrl;

  return (
    <Container>
      <div className="proxy-address-row">
        <div className="label">
          Api3ReaderProxyV1 address:
          <div className="chain-name">
            on <img src={ChainLogo(String(chainId))} width={18} height={18} alt={`${chainName} logo`} /> {chainName}
          </div>
        </div>
        {verificationStatus === 'not-verified' ? (
          isConnected ? (
            <Button
              className="get-proxy-button"
              variant="primary"
              color="blue"
              sm={{
                size: 'medium',
              }}
              onClick={deployProxy}
              loading={deploying}
            >
              Deploy Proxy
            </Button>
          ) : (
            <ConnectButton variant="primary" color="blue" size="small" md={{ size: 'large' }}>
              Connect Wallet
            </ConnectButton>
          )
        ) : (
          <div className="proxy-address-container">
            <VerifiedProxyAddress proxyAddress={proxyAddress} verificationStatus={verificationStatus} />
            {explorerUrl && (
              <Button
                variant="link"
                color="blue"
                size="small"
                sm={{ size: 'large' }}
                disabled={!proxyAddress}
                iconEnd={<ExternalLinkIcon className="external-link-icon" />}
                href={proxyAddress && createExplorerUrl(explorerUrl, proxyAddress, 'address')}
              >
                View on block explorer
              </Button>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 1px solid var(--color-dark-blue-300);
  border-top: 1px solid var(--color-dark-blue-300);
  padding: 24px 0;
  margin: 0 auto 24px auto;
  width: 100%;

  .proxy-address-row {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .label {
    ${fonts.body[8]};
    color: var(--color-base-light);

    .chain-name {
      ${fonts.body[12]};
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .proxy-address-container {
    a {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .external-link-icon {
      width: 10px;
      height: 10px;
      margin: 0;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    gap: 16px;
    margin-bottom: 32px;

    .proxy-address-row {
      flex-direction: row;
      justify-content: space-between;
    }

    .label {
      ${fonts.body[5]};

      .chain-name {
        ${fonts.body[9]};

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .proxy-address-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .external-link-icon {
        width: 14px;
        height: 14px;
      }
    }
  }
`;
