import { go } from '@api3/promise-utils';
import { useHandleApiError } from 'hooks';
import { LIMITED_CACHING } from 'constants/caching';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { decodeUpdateParameters } from 'utils/contracts';
import { Subscription } from 'types';
import { getDapiPathName } from 'utils/utils';

const ENDPOINT_URL = 'https://api3dao.github.io/data-feeds/market/dapi-pricing';

interface DapiPricingMerkleTreeValue {
  value: string[];
  proof: string[];
}

interface DapiPricingMerkleTree {
  merkleRoot: string;
  leaves: DapiPricingMerkleTreeValue[];
}

interface PricingData {
  merkleTreeRoot: string;
  subscriptions: Subscription[];
}

const readPricingMerkleTree = async (chainId: string, dapiName: string) => {
  const url = `${ENDPOINT_URL}/${chainId}/${getDapiPathName(dapiName)}.json`;
  const result = await go(() => axios.get<DapiPricingMerkleTree>(url, {}));
  if (result.error) {
    throw result.error;
  }

  return parsePricingMerkleTree(result.data.data);
};

const parsePricingMerkleTree = (rawPricingData: DapiPricingMerkleTree): PricingData => {
  // See https://github.com/api3dao/dapi-management/tree/main/scripts#1-dapi-pricing-merkle-tree

  const subscriptions = rawPricingData.leaves
    .map(({ value, proof }) => {
      const updateParameters = decodeUpdateParameters(value[2]);
      const duration = parseInt(value[3], 10);
      const price = value[4];

      return {
        updateParameters,
        duration,
        price,
        priceInTheMerkleTree: price,
        proof,
      };
    })
    .sort((a, b) => a.updateParameters.deviationThreshold - b.updateParameters.deviationThreshold);

  return {
    subscriptions,
    merkleTreeRoot: rawPricingData.merkleRoot,
  };
};

export const usePricingMerkleTree = (chainId: string, dapiName: string) => {
  const { data, error, isError, isSuccess } = useQuery({
    queryKey: ['merkleTree', chainId, dapiName],
    queryFn: () => readPricingMerkleTree(chainId, dapiName),
    ...LIMITED_CACHING,
  });

  useHandleApiError(error, isError, 'Unable to load data feed pricing data.');

  return {
    ...data,
    isError,
    isSuccess,
  };
};
