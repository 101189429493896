import {
  api3ApiIntegrations,
  api3Contracts,
  dapiManagementMerkleTreeData,
  dapis,
  getChains,
} from '@api3/dapi-management';
import keyBy from 'lodash/keyBy';
import { BeaconData } from 'types';
import { slugify } from './format';

export { api3ApiIntegrations };

export const getActiveOrDeprecatedChainIds = () => {
  return getChains()
    ?.filter((chain) => chain.stage === 'active' || chain.stage === 'deprecated')
    .map((chain) => chain.id);
};

export const getActiveOrDeprecatedDapiNames = () => {
  return dapis
    .filter((dapi) => dapi.stage === 'active' || dapi.stage === 'deprecated')
    .map((dapi) => dapi.name)
    .sort();
};

export const isDeprecatedChain = (chainId?: string) => {
  if (!chainId) return false;
  return getChains().find((chain) => chain.id === chainId)?.stage === 'deprecated';
};

export const isDeprecatedDapi = (dapiName?: string) => {
  if (!dapiName) return false;
  return dapisByName[dapiName]?.stage === 'deprecated';
};

export const isDeprecatedDataFeed = (chainId: string, dapiName?: string) => {
  return isDeprecatedChain(chainId) || isDeprecatedDapi(dapiName);
};

export const getBeaconByAirnodeAddress = (beacons: BeaconData[], airnodeAddress: string) => {
  return beacons.find((beacon) => beacon.airnodeAddress === airnodeAddress);
};

export const getProviderAliasesToNames = (beacons: BeaconData[]) => {
  const providersData = beacons.map((beacon) => api3ApiIntegrations.getApiUrls(beacon.airnodeAddress));
  return Object.fromEntries(providersData.map((data) => [data.alias, data.name]));
};

export const getDapiManagementMerkleTreeValues = () => {
  return dapiManagementMerkleTreeData.merkleTreeValues;
};

export const API3_CHAINS = api3Contracts.CHAINS;

export const dapisByName = keyBy(
  dapis.filter(({ stage }) => ['active', 'deprecated'].includes(stage)),
  'name'
);
export const dapisBySlug = keyBy(
  dapis.filter(({ stage }) => ['active', 'deprecated'].includes(stage)),
  (dapi: { name: string }) => slugify(dapi.name)
);
export const allChainsByAlias = keyBy(API3_CHAINS, 'alias');
export const allChainsById = keyBy(API3_CHAINS, 'id');
export const activeOrDeprecatedChainIds = keyBy(getActiveOrDeprecatedChainIds());

// Adding a chain alias into this array will effectively hide the network from the Market
export const HIDDEN_CHAINS = ['oev-network', 'hyperliquid', 'hyperliquid-testnet'];
