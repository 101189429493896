import { useEffect, useState } from 'react';
import { api3Contracts } from '@api3/dapi-management';
import { useChain } from 'hooks/chain-data';
import { Subscription } from 'types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { usePricingMerkleTree } from './use-pricing-merkle-tree';
import { computeSubscriptionPrices } from '../utils/compute-prices';

export const usePurchasableSubscriptions = (chain: api3Contracts.Chain, dapiName: string) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { provider } = useChain(chain.alias);
  const selectedSubscriptionIndex = Number(searchParams.get('paramsId'));
  const [subscriptions, setSubscriptions] = useState<Subscription[]>();

  const setSelectedSubscription = (index: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('default');
    newParams.set('paramsId', index.toString());
    navigate(`?${newParams.toString()}`, { replace: true });
  };

  const {
    subscriptions: merkleTreeSubscriptions,
    merkleTreeRoot: pricingMerkleTreeRoot,
    isError,
  } = usePricingMerkleTree(chain.id, dapiName);

  useEffect(() => {
    async function handleSubscriptions() {
      if (provider && merkleTreeSubscriptions && merkleTreeSubscriptions.length) {
        const buyableSubscriptions = await computeSubscriptionPrices(
          chain.id,
          dapiName,
          provider,
          merkleTreeSubscriptions
        );
        setSubscriptions(buyableSubscriptions);

        const defaultParam = searchParams.get('default');
        const defaultDeviationThreshold = defaultParam && Number(defaultParam);

        // If there is an active subscription, select it as default
        if (defaultDeviationThreshold) {
          const activeSubscriptionIndex = buyableSubscriptions.findIndex(
            (subscription) => subscription.updateParameters.deviationThreshold === defaultDeviationThreshold
          );

          if (activeSubscriptionIndex !== -1) {
            setSelectedSubscription(activeSubscriptionIndex);
            return;
          }
        }

        // If the default subscription is not present, select the first one as default
        if (!buyableSubscriptions[selectedSubscriptionIndex]) {
          setSelectedSubscription(0);
        }
      }
    }

    handleSubscriptions();
  }, [provider, merkleTreeSubscriptions]);

  return {
    selectedSubscriptionIndex,
    subscriptions,
    setSelectedSubscription,
    pricingMerkleTreeRoot,
    isError,
  };
};
