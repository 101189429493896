import { useEffect } from 'react';
import { allChainsByAlias, isDeprecatedDataFeed } from 'utils/dapis';
import { getDapiPath } from 'utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePageParams } from './use-page-params';
import { useActiveDapis } from './chain-data/use-active-dapis';

// Loads the active dapis if there is chain alias in the URL
// Keeps loading animation displayed until the active dapis are loaded or an error occurs
export const useLoader = () => {
  const { chainAlias, dapiName } = usePageParams();
  const chain = allChainsByAlias[chainAlias || ''];
  const { activeFeeds, isError } = useActiveDapis(chain?.id);
  const location = useLocation();
  const navigate = useNavigate();

  const activeFeedsLoaded = !!activeFeeds || isError;
  const notActiveDapi = !isError && dapiName && chain && activeFeeds && !activeFeeds?.includes(dapiName);
  const isOnFeedPage = location.pathname.match(/^\/[a-z\-]+\/[a-z0-9\-]+[\/]*$/);
  const isOnIntegratePage = location.pathname.match(/^\/[a-z\-]+\/[a-z0-9\-]+\/integrate.*$/);
  const isOnActivatePage = location.pathname.match(/^\/[a-z\-]+\/[a-z0-9\-]+\/activate.*$/);
  const redirectToActivatePage = (isOnFeedPage || isOnIntegratePage) && notActiveDapi;
  const redirectToNotFoundPage = isOnActivatePage && isDeprecatedDataFeed(chain?.id, dapiName);
  const hidePageContent = (chainAlias && !activeFeedsLoaded) || redirectToActivatePage;

  // Hide the loading cube when the new page is fully loaded
  useEffect(() => {
    const loadingScreen = document.querySelector<HTMLElement>('.loading-screen');
    if (loadingScreen && !hidePageContent) {
      loadingScreen.style.display = 'none';
    }
  }, [hidePageContent, location]);

  // Redirect to activate page if we are on the feed/integrate page and the dapi is not active
  useEffect(() => {
    if (redirectToActivatePage) {
      navigate(`${getDapiPath(chain.alias, dapiName)}/activate`, {
        replace: true,
      });
    }

    if (redirectToNotFoundPage) {
      navigate('/404', {
        replace: true,
      });
    }
  }, [redirectToActivatePage, redirectToNotFoundPage, navigate]);

  // Display loading screen when page content gets hidden
  useEffect(() => {
    const loadingScreen = document.querySelector<HTMLElement>('.loading-screen');
    if (hidePageContent && loadingScreen) {
      loadingScreen.style.display = 'block';
    }
  }, [hidePageContent, location]);

  return {
    hidePageContent,
  };
};
