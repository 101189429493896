import { ComponentPropsWithoutRef } from 'react';

interface RadioIconProps extends ComponentPropsWithoutRef<'svg'> {
  backgroundFill?: string;
  borderStroke?: string;
  circleFill?: string;
}

export const RadioIcon = ({
  backgroundFill = '#0C1143',
  borderStroke = '#D1D5FD',
  circleFill = '#D1D5FD',
  ...props
}: RadioIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <rect x="0.625" y="0.625" width="18.75" height="18.75" rx="9.375" fill={backgroundFill} />
      <rect x="0.625" y="0.625" width="18.75" height="18.75" rx="9.375" stroke={borderStroke} strokeWidth="1.25" />
      <circle cx="10" cy="10" r="5" fill={circleFill} />
    </svg>
  );
};
