import { ethers } from 'ethers';
import { StandardMerkleTree } from '@openzeppelin/merkle-tree';
import { deriveSponsorWalletAddress, api3Contracts } from '@api3/dapi-management';
import { go } from '@api3/promise-utils';
import { dapisByName, api3ApiIntegrations, getDapiManagementMerkleTreeValues } from 'utils/dapis';
import { Beacon, Subscription } from 'types';
import {
  deriveBeaconId,
  deriveBeaconSetId,
  encodeBeaconData,
  encodeDapiName,
  encodeUpdateParameters,
  getApi3Market,
  computeProxyAddress,
} from 'utils/contracts';
import { PURCHASE_TEST_PROVIDER, IS_PURCHASE_TEST } from '__test_utils__/purchase-test';
import { DEFAULT_PROXY_DAPP_ID } from 'constants/constants';
import { getBeaconSetUpdateCalldata, getBeaconsUpdateCalldatas } from './update-feeds';

export const prepareBeaconsData = async (api3Market: api3Contracts.Api3MarketV2, dapiName: string) => {
  if (!dapisByName[dapiName]) {
    // This guard is for tests only, should never happen in production because of useValidatePageParams hook
    throw new Error(`Data Feed ${dapiName} is not purchasable`);
  }
  const now = Math.floor(Date.now() / 1000);
  const oneDay = BigInt(24 * 60 * 60);

  const { getAirnodeAddressByAlias, getOisTitleByFeedNameAndAirnodeAddress, deriveTemplateId } = api3ApiIntegrations;

  const beacons: Beacon[] = dapisByName[dapiName].providers.map((provider) => {
    const airnodeAddress = getAirnodeAddressByAlias(provider);
    const oisTitle = getOisTitleByFeedNameAndAirnodeAddress(dapiName, airnodeAddress);
    const templateId = deriveTemplateId({ feedName: dapiName, oisTitle, airnodeAddress });
    const beaconId = deriveBeaconId(airnodeAddress, templateId);

    return {
      airnodeAddress,
      templateId,
      beaconId,
    };
  });

  const beaconIds = beacons.map((beacon) => beacon.beaconId);
  const airnodes = beacons.map((beacon) => beacon.airnodeAddress);
  const templateIds = beacons.map((beacon) => beacon.templateId);
  const dataFeedId = beacons.length === 1 ? deriveBeaconId(airnodes[0], templateIds[0]) : deriveBeaconSetId(beaconIds);
  const dataFeedDetails = encodeBeaconData(airnodes, templateIds);

  const { returndata } = await api3Market.tryMulticall.staticCall([
    api3Market.interface.encodeFunctionData('getDataFeedData', [dataFeedId]),
    api3Market.interface.encodeFunctionData('registerDataFeed', [dataFeedDetails]),
    api3Market.interface.encodeFunctionData('getDataFeedData', [dataFeedId]),
  ]);

  const [
    _dataFeedDetailsBeforeRegistration,
    _dataFeedValueBeforeRegistration,
    _dataFeedTimestampBeforeRegistration,
    beaconValuesBeforeRegistration,
    _beaconTimestampsBeforeRegistration,
  ] = api3Market.interface.decodeFunctionResult('getDataFeedData', returndata[0]);

  const [_dataFeedDetails, _dataFeedValue, dataFeedTimestamp, beaconValues, beaconTimestamps] =
    api3Market.interface.decodeFunctionResult('getDataFeedData', returndata[2]);

  // if the first call returns some data, the data feed is already registered
  const isRegistered = beaconValuesBeforeRegistration.length !== 0;

  // filter out beacons that were updated last day
  const beaconsNeedingUpdate = beacons.filter((_, index) => beaconTimestamps[index] + oneDay < now);

  // if beaconSet has been updated last day, we don't need to update it
  const updateBeaconSet = beaconValues.length > 1 && dataFeedTimestamp + oneDay < now;

  return {
    beacons,
    beaconIds,
    airnodes,
    templateIds,
    dataFeedId,
    isRegistered,
    beaconsNeedingUpdate,
    updateBeaconSet,
  };
};

export const getDataFeedRegistrationCalldatas = async (
  api3Market: api3Contracts.Api3MarketV2,
  airnodes: string[],
  templateIds: string[]
) => {
  const dataFeedDetails = encodeBeaconData(airnodes, templateIds);
  const calldatas = api3Market.interface.encodeFunctionData('registerDataFeed', [dataFeedDetails]);
  return [calldatas];
};

export const getDeployProxyCalldatas = async (
  api3Market: api3Contracts.Api3MarketV2,
  dapiName: string,
  chainId: string,
  signer: ethers.Signer
) => {
  const encodedDapiName = encodeDapiName(dapiName);
  const dappId = DEFAULT_PROXY_DAPP_ID;
  const { provider } = signer;
  if (!provider) {
    return [];
  }

  const { deployed } = await computeProxyAddress(chainId, dapiName, dappId, provider);

  // Do not return any deploy calldata if the proxy is already deployed
  if (deployed) {
    return [];
  }

  const calldatas = api3Market.interface.encodeFunctionData('deployApi3ReaderProxyV1', [encodedDapiName, dappId, '0x']);

  return [calldatas];
};

export const buySubscription = async (
  calldatas: string[],
  api3Market: api3Contracts.Api3MarketV2,
  dapiName: string,
  dataFeedId: string,
  subscription: Subscription,
  pricingMerkleTreeRoot: string
) => {
  const encodedDapiName = encodeDapiName(dapiName);
  const sponsorWalletAddress = deriveSponsorWalletAddress(encodedDapiName);
  const tree = StandardMerkleTree.of(getDapiManagementMerkleTreeValues(), ['bytes32', 'bytes32', 'address']);
  const proof = tree.getProof([encodedDapiName, dataFeedId, sponsorWalletAddress]);

  const dapiManagementAndDapiPricingMerkleData = ethers.AbiCoder.defaultAbiCoder().encode(
    ['bytes32', 'bytes32[]', 'bytes32', 'bytes32[]'],
    [tree.root, proof, pricingMerkleTreeRoot, subscription.proof]
  );

  const encodedUpdateParameters = encodeUpdateParameters(subscription.updateParameters);
  const price = BigInt(subscription.priceInTheMerkleTree);
  const fundsToSend = subscription.discountedPrice || subscription.price;

  // Estimate gas
  const goEstimateGas = await go(() =>
    api3Market.multicallAndBuySubscription.estimateGas(
      calldatas,
      encodedDapiName,
      dataFeedId,
      sponsorWalletAddress,
      encodedUpdateParameters,
      subscription.duration,
      price,
      dapiManagementAndDapiPricingMerkleData,
      { value: fundsToSend }
    )
  );
  if (!goEstimateGas.success) {
    throw goEstimateGas.error;
  }

  if (IS_PURCHASE_TEST) {
    // In test mode we end here, not making the actual purchase
    throw new Error('Testing purchasability, not executing transaction');
  }

  // Adding a extra 10% because multicall consumes less gas than tryMulticall
  const gasLimit = goEstimateGas.data + goEstimateGas.data / BigInt(10);

  // Execute transaction
  const tx = await api3Market.tryMulticallAndBuySubscription(
    calldatas,
    encodedDapiName,
    dataFeedId,
    sponsorWalletAddress,
    encodedUpdateParameters,
    subscription.duration,
    price,
    dapiManagementAndDapiPricingMerkleData,
    { gasLimit, value: fundsToSend }
  );

  return tx;
};

// See more here: https://github.com/api3dao/contracts/blob/phase21/docs/contracts/api3market.md
export const activateDataFeed = async (
  chainId: string,
  dapiName: string,
  signer: ethers.Signer,
  subscription: Subscription,
  merkleTreeRoot: string
) => {
  // Connect to contracts
  const api3Market = await getApi3Market(chainId, IS_PURCHASE_TEST ? PURCHASE_TEST_PROVIDER : signer);

  // Prepare data for the multicall
  const { beaconIds, airnodes, templateIds, dataFeedId, isRegistered, beaconsNeedingUpdate, updateBeaconSet } =
    await prepareBeaconsData(api3Market, dapiName);

  const registrationCalldatas = !isRegistered
    ? await getDataFeedRegistrationCalldatas(api3Market, airnodes, templateIds)
    : [];
  const beaconsUpdateCalldatas = await getBeaconsUpdateCalldatas(api3Market, beaconsNeedingUpdate);
  const beaconSetUpdateCalldatas = updateBeaconSet ? getBeaconSetUpdateCalldata(api3Market, beaconIds) : [];
  const deployProxyCalldatas = await getDeployProxyCalldatas(api3Market, dapiName, chainId, signer);
  const purchasePreparationCalldatas = [
    ...registrationCalldatas,
    ...beaconsUpdateCalldatas,
    ...beaconSetUpdateCalldatas,
    ...deployProxyCalldatas,
  ];

  // Execute multicall and return the response
  const tx = await buySubscription(
    purchasePreparationCalldatas,
    api3Market,
    dapiName,
    dataFeedId,
    subscription,
    merkleTreeRoot
  );

  return tx;
};
