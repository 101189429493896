import styled from 'styled-components';
import { KeyboardEvent } from 'react';
import DatafeedLogos from 'components/shared/datafeed/datafeed-logos';
import { breakpoints, gradientBorder } from 'styles/theme';
import { StatusActiveIcon, StatusInactiveIcon } from 'components/shared/icons';
import { getDapiPath } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { useURLWithShowHidden, useWindowDimensions } from 'hooks';
import Skeleton from 'components/shared/skeleton';
import { DapiName } from 'components/shared/datafeed/dapi-name';
import { fonts } from 'styles/fonts';
import clsx from 'clsx';

interface Props {
  chainAlias: string;
  dapiName: string;
  onlySmall?: boolean;
}

export const ChainDApiCard = (props: Props) => {
  const { chainAlias, dapiName, onlySmall } = props;
  const { getURL } = useURLWithShowHidden();
  const navigate = useNavigate();

  if (!chainAlias) {
    return null;
  }

  const handleClick = () => {
    const newPath = getDapiPath(chainAlias, dapiName);
    navigate(getURL(newPath));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.stopPropagation();

      handleClick();
    }
  };

  const dataFeedLogosProps = onlySmall
    ? { size: 'lg' as const }
    : { size: 'lg' as const, md: { size: 'xl' as const }, lg: { size: 'xxl' as const } };

  return (
    <Card
      className={clsx('chain-dapi-card', { small: onlySmall })}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      data-testid="dapi-card"
    >
      <StatusActiveIcon className="chain-card-status-icon" />
      <DatafeedLogos dapiName={dapiName} {...dataFeedLogosProps} />
      <DapiName name={dapiName} className="chain-card-dapi-name" />
    </Card>
  );
};

export const ChainDapiSkeleton = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <Card className="card-loading" data-testid="dapi-card-skeleton">
      <StatusInactiveIcon className="chain-card-status-icon" />
      <DatafeedLogos size="lg" />
      <Skeleton width="100px" />
      {!isMobile && <Skeleton width="100px" />}
    </Card>
  );
};

const Card = styled.li`
  list-style: none;
  position: relative;
  background:
    radial-gradient(183.92% 131.37% at 50.04% 13.63%, rgba(44, 54, 161, 0.4) 0%, rgba(9, 13, 56, 0) 100%), #050818;
  border: 1px solid var(--color-dark-blue-400);
  min-width: 156px;
  width: 156px;
  height: 188px;
  border-radius: 20px;
  padding: 52px 0 36px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--color-dark-blue-100); // Fallback for browsers who don't support gradient border
    ${gradientBorder('blue-01-stroke', -1)}
  }

  &:active,
  &:focus {
    border: 1px solid var(--color-dark-blue-100); // Fallback for browsers who don't support gradient border
    ${gradientBorder('general-blue', -1)}
  }

  .chain-card-status-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
  }

  .chain-card-dapi-name {
    ${fonts.body[12]}
    text-align: center;
    width: 100%;
    padding: 0 16px;
  }

  @media (min-width: ${breakpoints.md}px) {
    &:not(.small) {
      min-width: 200px;
      width: 200px;
      height: 200px;
      gap: 24px;
      padding-top: 60px;

      .chain-card-dapi-name {
        ${fonts.body[9]}
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    &:not(.small) {
      min-width: 240px;
      width: 240px;
      height: 240px;
      gap: 24px;
      padding-top: 60px;

      .chain-card-dapi-name {
        ${fonts.body[6]}
      }
    }
  }
`;
