import styled from 'styled-components';
import { breakpoints, gradientBorder } from 'styles/theme';
import { fonts } from 'styles/fonts';
import { ReactElement, useState } from 'react';
import { Button, RadioButton, Tooltip } from 'components/shared';
import clsx from 'clsx';
import { SearchBox } from 'components/shared/search-box/search-box';
import { DAPPS, getDappAliasByTitle, getDappByAlias } from 'utils/contracts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePageParams, useURLWithShowHidden } from 'hooks';
import { getDapiPath } from 'utils/utils';
import { useChain } from 'hooks/chain-data';
import { checkPhraseMatchesSearchQuery } from 'utils/dapi-search';
import { links } from 'constants/links';

type ProxyType = 'comunal' | 'with-oev-rewards';
const allDappTitles = DAPPS.flatMap((dapp) => Object.values(dapp.aliases).map(({ title }) => title));

export const ProxySelector = () => {
  const navigate = useNavigate();
  const { getURL } = useURLWithShowHidden();
  const { chainAlias, dapiName } = usePageParams();
  const { chain } = useChain(chainAlias);

  // Get dapp from URL
  const [searchParams] = useSearchParams();
  const dappAlias = searchParams.get('dappAlias');
  const dappFromUrl = dappAlias ? getDappByAlias(dappAlias) : null;

  const [searchValue, setSearchValue] = useState<string>(dappFromUrl ? dappFromUrl.title : '');
  const [selectedProxyType, setSelectedProxyType] = useState<ProxyType>(
    dappAlias === null ? 'comunal' : 'with-oev-rewards'
  );

  const handleSearchSubmit = (value: string) => {
    if (!chainAlias || !dapiName) return;

    const matchingDapps = allDappTitles.filter((dappTitle) => checkPhraseMatchesSearchQuery(dappTitle, value));

    if (matchingDapps.length === 1 && matchingDapps[0].toLowerCase() === value.toLowerCase()) {
      setSearchValue(matchingDapps[0]);
      const dappAlias = getDappAliasByTitle(matchingDapps[0]);
      navigate(getURL(`${getDapiPath(chainAlias, dapiName)}/integrate?dappAlias=${dappAlias}`));
    }
  };

  const handleChangeProxyType = (proxyType: ProxyType) => {
    setSelectedProxyType(proxyType);
    setSearchValue('');
    if (chainAlias && dapiName) {
      const dappParam = proxyType === 'with-oev-rewards' ? '?dappAlias=' : '';
      navigate(getURL(`${getDapiPath(chainAlias, dapiName)}/integrate${dappParam}`));
    }
  };

  const handleSearchClear = () => {
    setSearchValue('');
    if (chainAlias && dapiName) {
      navigate(getURL(`${getDapiPath(chainAlias, dapiName)}/integrate?dappAlias=`));
    }
  };

  const matchingDapps = allDappTitles.filter((dappTitle) => checkPhraseMatchesSearchQuery(dappTitle, searchValue));

  return (
    <Container>
      <div className="proxy-type-radio-buttons">
        <RadioButton
          name="proxy"
          value="comunal"
          label="Skip OEV Rewards"
          onChange={() => handleChangeProxyType('comunal')}
          checked={selectedProxyType === 'comunal'}
          className={clsx('radio-button', { checked: selectedProxyType === 'comunal' })}
        />
        <TooltipWrapper tooltip={chain?.testnet ? 'OEV Rewards is unavailable on testnets' : ''}>
          <RadioButton
            name="proxy"
            value="with-oev-rewards"
            label="Earn OEV Rewards"
            onChange={() => handleChangeProxyType('with-oev-rewards')}
            checked={selectedProxyType === 'with-oev-rewards'}
            className={clsx('radio-button', {
              checked: selectedProxyType === 'with-oev-rewards',
              disabled: chain?.testnet,
            })}
            disabled={chain?.testnet}
          />
        </TooltipWrapper>
      </div>

      <div
        className={clsx('dapp-search-bar-container', {
          expanded: selectedProxyType === 'with-oev-rewards',
          searching: !!searchValue,
        })}
      >
        <div className="dapp-search-bar">
          <div>dApp to earn rewards</div>
          <SearchBox
            className="search-dapp-box"
            placeholder="Search for dApp"
            searchValue={searchValue}
            userInput={searchValue}
            dropdownOptions={matchingDapps}
            searchResultsCount={matchingDapps.length}
            footer={<DropdownFooter />}
            onSearchChange={(value) => setSearchValue(value)}
            onSearchClear={handleSearchClear}
            onSearchSubmit={handleSearchSubmit}
            onDropdownOptionClick={handleSearchSubmit}
          />
        </div>
      </div>
    </Container>
  );
};

interface TooltipWrapperProps {
  children: ReactElement;
  tooltip?: string;
}

const TooltipWrapper = ({ children, tooltip }: TooltipWrapperProps) => {
  return tooltip ? (
    <Tooltip content={tooltip}>
      <Tooltip.Trigger>{children}</Tooltip.Trigger>
    </Tooltip>
  ) : (
    children
  );
};

const DropdownFooter = () => {
  return (
    <DropdownFooterContainer className="dropdown-footer">
      <div className="dropdown-footer-text">Can’t find your dApp?</div>
      <Button
        variant="link"
        href={links.FORM_REQUEST_DAPP_ALIAS}
        color="blue"
        theme="light"
        size="small"
        // sm={{ size: 'large' }}
        iconEnd={null}
      >
        Apply for OEV Rewards
      </Button>
    </DropdownFooterContainer>
  );
};

const Container = styled.div`
  .proxy-type-radio-buttons {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 32px;

    .radio-button {
      padding: 8px 16px 10px 12px;
      border-radius: 6px;
      border: 1px solid var(--color-dark-blue-100);

      &.checked {
        background-color: var(--color-base-blue-ui);
      }

      &.disabled {
        border: 1px solid var(--color-dark-blue-400);
        pointer-events: none;
      }
    }
  }

  .dapp-search-bar-container {
    height: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    .dapp-search-bar {
      display: none;
      flex-direction: column;
      gap: 16px;
      ${fonts.body[11]};
    }

    &.expanded {
      margin-bottom: 24px;
      opacity: 1;
      height: 85px;

      .dapp-search-bar {
        display: flex;
      }
    }

    &.searching {
      overflow: visible;
    }
  }

  .search-dapp-box {
    width: 100%;
    max-width: 328px;
  }

  .search-box-clear-button svg {
    width: 20px;
    height: 20px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    .proxy-type-radio-buttons {
      margin-bottom: 40px;
      gap: 32px;

      .radio-button {
        padding: 8px 24px 10px 20px;
      }
    }

    .dapp-search-bar-container {
      .dapp-search-bar {
        display: none;
        flex-direction: row;
        align-items: center;
        gap: 40px;
        ${fonts.body[8]};
      }

      &.expanded {
        height: 48px;
        margin-top: 8px;

        .dapp-search-bar {
          display: flex;
        }
      }
    }

    .search-dapp-box {
      width: 260px;
      height: 48px;

      .search-box-inner {
        height: 48px;

        .search-box-icon {
          min-height: 16px;
          min-width: 16px;
        }

        .search-box-input,
        .search-box-input::placeholder {
          ${fonts.body[12]}
        }
      }
    }
  }
`;

const DropdownFooterContainer = styled.li`
  &.dropdown-footer:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 16px 0 8px 0;
    background-color: var(--color-base-light);
    ${gradientBorder('general-medium-light')};

    .dropdown-footer-text {
      ${fonts.body[15]};
      color: var(--color-dark-blue-900);
    }
  }
`;
