import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api3Contracts } from '@api3/dapi-management';
import { useChain, useGetUserBalance } from 'hooks/chain-data';
import { Subscription, TransactionStatus } from 'types';
import { toasts } from 'components/notifications';
import { TransactionResponse, TransactionReceipt } from 'ethers';
import { walletMessages } from 'constants/walletMessages';
import { go } from '@api3/promise-utils';
import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { useQueryClient } from '@tanstack/react-query';
import { activateDataFeed } from '../utils/activate-data-feed';

export const useActivateTransaction = (
  chain: api3Contracts.Chain,
  dapiName: string,
  pricingMerkleTreeRoot?: string,
  subscription?: Subscription
) => {
  const [tx, setTx] = useState<TransactionResponse>();
  const [txReceipt, setTxReceipt] = useState<TransactionReceipt | null>(null);
  const [txStatus, setTxStatus] = useState<TransactionStatus>();
  const queryClient = useQueryClient();

  const { provider, signer } = useChain(chain.alias);
  const { userBalance } = useGetUserBalance();

  const { setErrorBanner } = useNotificationBannerContext();

  const activate = async () => {
    if (!provider || !signer) {
      toasts.error({
        message: 'You need to be connected to activate the data feed',
        errorToReport: `Provider or Signer missing during purchase. Provider: ${provider}, Signer: ${signer}`,
      });
      return;
    }

    if (!subscription || !pricingMerkleTreeRoot) {
      // Shouldn't happen in reality
      toasts.error({
        message: 'Missing data needed for purchase',
        errorToReport: `Subscription data missing during purchase. Subscription: ${subscription}, pricingMerkleTreeRoot: ${pricingMerkleTreeRoot}`,
      });
      return;
    }

    const fundsToSend = subscription.discountedPrice || subscription.price;
    if (userBalance < BigInt(fundsToSend)) {
      toasts.error({ message: 'Insufficient funds in the connected wallet' });
      return;
    }

    setTxStatus('preparing');

    const result = await go(() => activateDataFeed(chain.id, dapiName, signer, subscription, pricingMerkleTreeRoot));

    if (result.success) {
      toasts.info({
        message: 'Pending',
        secondaryMessage: 'Your transaction is being confirmed.',
      });
      setTx(result.data);
      setTxStatus('pending');
      return;
    }

    if ((result.error as any)?.code === 'ACTION_REJECTED') {
      setTxStatus(undefined);
      toasts.info({
        message: walletMessages.TX_GENERIC_REJECTED,
      });
      return;
    }

    setTxStatus('error');
    const errorMessageWithoutDetails = result.error.message.split('(')[0].trim();

    toasts.error({
      message: 'Transaction Failed',
      secondaryMessage: errorMessageWithoutDetails,
      errorToReport: result.error,
    });

    setErrorBanner({
      message: 'Transaction Failed.',
      secondaryMessage: errorMessageWithoutDetails,
    });
  };

  useEffect(() => {
    const waitForTransactionToFinalize = async () => {
      if (tx && !txReceipt) {
        const result = await go(() => tx.wait());

        toast.dismiss(); // Dismiss the pending toast

        if (result.success) {
          // Refetch the active dapis and the dapi chain data to reflect the new purchase
          queryClient.refetchQueries({ queryKey: ['activeDapis'] });
          queryClient.refetchQueries({ queryKey: ['dapiChainData', dapiName, chain.id] });

          setTxStatus('success');
          setTxReceipt(result.data);
          toasts.success({
            message: `${dapiName} purchase was successful!`,
            secondaryMessage: 'Your data feed is active and ready to use.',
          });

          return;
        }

        setTxStatus('error');

        const errorMessageWithoutDetails = result.error.message.split('(')[0].trim();

        toasts.error({
          message: 'Transaction Failed',
          secondaryMessage: errorMessageWithoutDetails,
          errorToReport: result.error,
        });
      }
    };

    waitForTransactionToFinalize();
  }, [tx]);

  return {
    tx,
    txReceipt,
    txStatus,
    activate,
  };
};
