import { ethers } from 'ethers';
import { go } from '@api3/promise-utils';
import { api3Contracts } from '@api3/dapi-management';
import { DEFAULT_PROXY_DAPP_ID, DEFAULT_PROXY_METADATA } from '../constants/constants';
import deploymentAddresses from './references.json';

const {
  AirseekerRegistry__factory: AirseekerRegistryFactory,
  Api3MarketV2__factory: Api3MarketV2Factory,
  Api3ReaderProxyV1Factory__factory: Api3ReaderProxyV1FactoryFactory,
} = api3Contracts;

export const getAirseekerRegistry = async (chainId: string, providerOrSigner: ethers.Provider | ethers.Signer) => {
  const AirseekerRegisterAddress =
    deploymentAddresses.AirseekerRegistry?.[chainId as keyof typeof deploymentAddresses.AirseekerRegistry];
  return AirseekerRegistryFactory.connect(AirseekerRegisterAddress, providerOrSigner);
};

export const getApi3Market = async (chainId: string, providerOrSigner: ethers.Provider | ethers.Signer) => {
  const api3MarketAddress =
    deploymentAddresses.Api3MarketV2?.[chainId as keyof typeof deploymentAddresses.Api3MarketV2];
  return Api3MarketV2Factory.connect(api3MarketAddress, providerOrSigner);
};

export const getApi3ReaderProxyV1Factory = async (
  chainId: string,
  providerOrSigner: ethers.Provider | ethers.Signer
) => {
  const api3ReaderProxyV1FactoryAddress =
    deploymentAddresses.Api3ReaderProxyV1Factory?.[
      chainId as keyof typeof deploymentAddresses.Api3ReaderProxyV1Factory
    ];
  return Api3ReaderProxyV1FactoryFactory.connect(api3ReaderProxyV1FactoryAddress, providerOrSigner);
};

export const computeProxyAddress = async (
  chainId: string,
  dapiName: string,
  dappId: bigint,
  provider: ethers.Provider
) => {
  const metadata = DEFAULT_PROXY_METADATA;
  const encodedDapiName = ethers.encodeBytes32String(dapiName);
  const api3ReaderProxyV1Factory = await getApi3ReaderProxyV1Factory(chainId, provider);

  const proxyAddress = await api3ReaderProxyV1Factory.computeApi3ReaderProxyV1Address(
    encodedDapiName,
    DEFAULT_PROXY_DAPP_ID,
    metadata
  );

  // Check if there's a proxy deployed at the computed address
  const result = await go(() => provider.getCode(proxyAddress));

  // If metadata found on the chain is different from the one from params, it means the proxy is already deployed
  return { proxyAddress, deployed: result.data !== metadata, metadata, ...result };
};

export const DAPPS: api3Contracts.Dapp[] = [
  { aliases: { sake: { chains: [], title: 'Sake Finance' } }, homepageUrl: 'https://www.sakefinance.com/' },
  { aliases: { scallop: { chains: [], title: 'Scallop' } }, homepageUrl: 'https://www.scallop.io/' },
  { aliases: { silo: { chains: [], title: 'Silo' } }, homepageUrl: 'https://www.silo.finance/' },
  { aliases: { spark: { chains: [], title: 'Spark' } }, homepageUrl: 'https://spark.fi/' },
  {
    aliases: { 'stacking-salmon': { chains: [], title: 'Stacking Salmon' } },
    homepageUrl: 'https://x.com/stackingsalmon',
  },
  { aliases: { stout: { chains: [], title: 'Stout' } }, homepageUrl: 'https://stout.fi/' },
  { aliases: { sumer: { chains: [], title: 'Sumer' } }, homepageUrl: 'https://sumer.money/' },
];
