import { usePageParams, useScrollToTop } from 'hooks';
import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useChain } from 'hooks/chain-data';
import { useEffect } from 'react';
import { isDeprecatedChain, isDeprecatedDapi } from 'utils/dapis';
import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { DeprecatedBanner } from 'components/layout/deprecated-banner';
import { FeedDetailsHeader } from './feed-details-header';
import { DetailsPageSidebar } from './details-page-sidebar';
import { OnChainValue } from './on-chain-value';

export const FeedDetailsPage = () => {
  useScrollToTop();
  const { chainAlias, dapiName } = usePageParams();
  const { chain } = useChain(chainAlias);
  const { setBanner } = useNotificationBannerContext();

  useEffect(() => {
    if (isDeprecatedChain(chain?.id)) {
      setBanner({
        content: <DeprecatedBanner type="chain" />,
        type: 'deprecated',
      });
    } else if (isDeprecatedDapi(dapiName)) {
      setBanner({
        content: <DeprecatedBanner type="feed" />,
        type: 'deprecated',
      });
    }

    return () => {
      setBanner({ content: null, type: 'deprecated' });
    };
  }, [chain, dapiName]);

  if (!chain || !dapiName) {
    return null;
  }

  return (
    <Container>
      <Helmet>
        <title>
          Api3 Market | {dapiName} on {chain.name}
        </title>
      </Helmet>
      <FeedDetailsHeader dapiName={dapiName} chain={chain} />
      <div className="main-section">
        <div className="main-section-inner">
          <OnChainValue dapiName={dapiName} chain={chain} />
        </div>
        <DetailsPageSidebar dapiName={dapiName} chain={chain} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-bottom: 40px;
  flex-direction: column;

  .main-section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;
    width: 100%;

    .main-section-inner {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: 64px;

    .main-section {
      flex-direction: row;

      .main-section-inner {
        min-width: 664px;
        margin: 0 16px 0 -16px;
        display: flex;
        align-items: center;
      }
    }
  }
`;
