import { ComponentProps } from 'react';

export const DocsIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M16.5708 0.941406H3.4458V19.0664H16.5708V0.941406Z" stroke="currentColor" />
      <path
        d="M5.3208 3.44141H14.6958M5.3208 5.62891H14.6958M5.3208 7.81641H14.6958M5.3208 10.0039H14.6958"
        stroke="currentColor"
      />
    </svg>
  );
};
