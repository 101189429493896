import { NON_ALPHANUMERIC_REGEX } from 'constants/constants';
import { format, intervalToDuration, secondsToHours, secondsToMinutes } from 'date-fns';
import { tz } from '@date-fns/tz';
import { ethers } from 'ethers';

export const formatDeviation = (deviationThreshold: number) => {
  return `${deviationThreshold}%`;
};

export const formatHeartbeat = (heartbeatInterval: number) => {
  const suffix = heartbeatInterval < 3600 ? 'min' : 'hr';

  const heartbeatValue = suffix === 'min' ? secondsToMinutes(heartbeatInterval) : secondsToHours(heartbeatInterval);

  return `${heartbeatValue}${suffix}`;
};

export const formatSubscriptionPeriod = (subscriptionPeriodInSeconds?: number) => {
  if (!subscriptionPeriodInSeconds) return '-';

  const subscriptionPeriodInDays = Math.floor(subscriptionPeriodInSeconds / 86400);
  const subscriptionPeriodInMonths = Math.floor(subscriptionPeriodInDays / 30);

  const suffix = subscriptionPeriodInMonths
    ? subscriptionPeriodInMonths === 1
      ? 'month'
      : 'months'
    : subscriptionPeriodInDays
      ? subscriptionPeriodInDays === 1
        ? 'day'
        : 'days'
      : '';

  return `${subscriptionPeriodInMonths || subscriptionPeriodInDays || '-'} ${suffix}`;
};

export const formatPrice = (priceInWei: string) => {
  const weiValue = BigInt(priceInWei);
  return ethers.formatEther(weiValue);
};

export const formatUpdateDate = (ts: number) => `${format(ts, 'yyyy-MM-dd | HH:mm:ss', { in: tz('UTC') })} UTC`;
export const formatExpiryDate = (ts: number) => format(ts, 'dd MMMM yyyy');
export const formatExpiryDateSimple = (ts: number) => format(ts, 'yyyy-MM-dd');

export const getFormattedRemainingTime = (time: Date | null) => {
  if (!time) return { minutes: 0, hours: 0, days: 0, formatted: '' };

  const {
    seconds = 0,
    minutes = 0,
    hours = 0,
    days = 0,
    months = 0,
    years = 0,
  } = intervalToDuration({
    start: new Date(),
    end: time,
  });

  const resultMinutes = Math.ceil(seconds / 60 + minutes);
  const resultDays = days + months * 30 + years * 365;

  const daysPart = resultDays > 0 ? (resultDays === 1 ? `${resultDays} day` : `${resultDays} days`) : '';
  const hoursPart = hours > 0 ? (hours === 1 ? `${hours} hour` : `${hours} hours`) : '';
  const minutesPart =
    resultMinutes > 0 ? (resultMinutes === 1 ? `${resultMinutes} minute` : `${resultMinutes} minutes`) : '';

  const result = `${daysPart} ${hoursPart} ${minutesPart}`.replace(/\s+/g, ' ').trim();

  return result || '1 minute';
};

export const slugify = (text: string) => text.toLowerCase().replace(NON_ALPHANUMERIC_REGEX, '-');
