import styled from 'styled-components';
import { BackButton } from 'components/back-button';
import { Button } from 'components/shared';
import { breakpoints } from 'styles/theme';
import { usePageParams } from 'hooks';
import { links } from 'constants/links';
import { fonts } from 'styles/fonts';

export const IntegratePageHeader = () => {
  const { dapiName } = usePageParams();

  return (
    <Container className="integrate-page-header">
      <BackButton />

      <div className="integrate-page-header-inner">
        <div className="integrate-page-title">
          <h1>Integrate {dapiName}</h1>
          <Button
            variant="link"
            color="blue"
            size="small"
            sm={{
              size: 'large',
            }}
            href={links.DOCS_PROXY}
          >
            Visit Docs
          </Button>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  margin: 0 0 48px 0;

  .integrate-page-header-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    .integrate-page-title {
      display: flex;
      flex-direction: column;
      gap: 6px;

      h1 {
        ${fonts.heading[8]};
        margin: 0;
        color: var(--color-base-light);
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    gap: 16px;
    margin-bottom: 40px;

    .integrate-page-header-inner {
      flex-direction: row;
      align-items: center;
      gap: 32px;

      .integrate-page-title {
        gap: 8px;

        h1 {
          ${fonts.heading[6]};
        }
      }
    }
  }
`;
