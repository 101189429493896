import { api3Contracts } from '@api3/dapi-management';
import { Chain } from '@wagmi/chains';
import { defaultWagmiConfig } from '@web3modal/wagmi';

if (!process.env.VITE_APP_PROJECT_ID) {
  throw new Error('Missing VITE_APP_PROJECT_ID env variable');
}

// Taken from here: https://explorer.walletconnect.com/
export const walletIds = {
  METAMASK: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  WALLET_CONNECT: 'wallee7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3btconnect',
  COINBASE: 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
};

const localhostChain = {
  nativeCurrency: {
    name: 'Localhost',
    symbol: 'ETH',
    decimals: 18,
  },
  name: 'Localhost',
  network: 'localhost',
  id: 31337,
  rpcUrls: {
    default: {
      http: ['http://127.0.0.1:8545'] as const,
    },
    environment: {
      http: ['http://127.0.0.1:8545'],
    },
    public: {
      http: ['http://127.0.0.1:8545'] as const,
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer' as const,
      url: 'http://127.0.0.1:8545',
    },
  },
  testnet: true,
};

export const projectId = process.env.VITE_APP_PROJECT_ID;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const arrayFromChains = api3Contracts.viemConfig.chains().map(({ fees, ...rest }) => rest);

// add hardhat to the chains in the development environment
if (process.env.NODE_ENV === 'development') {
  arrayFromChains.push(localhostChain);
}

export const chains: [Chain, ...Chain[]] = [arrayFromChains[0], ...arrayFromChains];

const metadata = {
  name: 'Api3 Market',
  description: 'Oracles that pay you',
  url: 'https://market.api3.org',
  icons: ['https://market.api3.org/favicons/favicon-32.png'],
  verifyUrl: 'https://market.api3.org',
};

export const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableCoinbase: false,
  auth: {
    email: false,
    socials: [],
    showWallets: true,
    walletFeatures: true,
  },
});
