import { forwardRef, InputHTMLAttributes, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { fonts } from 'styles/fonts';
import { breakpoints } from 'styles/theme';
import { RadioIcon } from './icons/radio';

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  onChange: () => void;
}

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ label, checked, onChange, name, value, className, disabled, ...props }, ref) => {
    const id = `radio-${name}-${value}`;

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (!disabled && (event.key === 'Enter' || event.key === ' ')) {
        event.preventDefault();
        onChange();
      }
    };

    return (
      <Container
        tabIndex={0}
        role="radio"
        aria-checked={checked}
        aria-disabled={disabled}
        onKeyDown={handleKeyDown}
        className={className}
        ref={ref}
      >
        <input
          type="radio"
          id={id}
          checked={checked}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        <label htmlFor={id}>
          <RadioIcon
            backgroundFill="var(--radio-button-background-color)"
            borderStroke="var(--radio-button-border-color)"
            circleFill={checked ? 'var(--radio-button-border-color)' : 'var(--radio-button-background-color)'}
          />
          {label}
        </label>
      </Container>
    );
  }
);

const Container = styled.div`
  --radio-button-background-color: var(--color-dark-blue-700);
  --radio-button-border-color: var(--color-dark-blue-10);

  &:hover:not([aria-disabled='true']) {
    --radio-button-background-color: var(--color-dark-blue-800);
    --radio-button-border-color: var(--color-dark-blue-25);
  }

  &[aria-disabled='true'] {
    --radio-button-border-color: var(--color-dark-blue-200);
    --radio-button-background-color: var(--color-dark-blue-400);

    label {
      color: var(--color-dark-blue-100);
    }
  }

  display: flex;
  align-items: center;

  input[type='radio'] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    ${fonts.body[12]};
  }

  label svg {
    width: 16px;
    height: 16px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    label {
      gap: 12px;
      ${fonts.body[3]};
    }

    label svg {
      width: 20px;
      height: 20px;
    }
  }
`;
