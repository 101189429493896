import { api3Contracts } from '@api3/dapi-management';
import { go } from '@api3/promise-utils';
import { Beacon, SignedApiData, SignedApiResponse } from 'types';

// Fetch the signed API data for each beacon
export const getSignedApiData = async (beacons: Beacon[]) => {
  const signedApiDataPromises = beacons.map(async (beacon) => {
    const response = await fetch(`https://signed-api.api3.org/public/${beacon.airnodeAddress}`);
    const json = await response.json();
    return json.data as SignedApiResponse;
  });

  const result = await go(() => Promise.all(signedApiDataPromises));

  if (result.error) {
    throw new Error('Failed to fetch signed API data');
  }

  return result.data.map((response, index) => {
    const { beaconId } = beacons[index];
    return response[beaconId];
  });
};

// Prepare data for calling the updateBeaconWithSignedData in the multicall
export const getBeaconsUpdateCalldatas = async (api3Market: api3Contracts.Api3MarketV2, beacons: Beacon[]) => {
  const signedApiData = await getSignedApiData(beacons);

  const updateBeaconsCalldata = signedApiData
    .filter((data): data is SignedApiData => data !== undefined)
    .map((signedData) =>
      api3Market.interface.encodeFunctionData('updateBeaconWithSignedData', [
        signedData.airnode,
        signedData.templateId,
        signedData.timestamp,
        signedData.encodedValue,
        signedData.signature,
      ])
    );

  return updateBeaconsCalldata;
};

export const getBeaconSetUpdateCalldata = (api3Market: api3Contracts.Api3MarketV2, beaconIds: string[]) => {
  const calldatas = api3Market.interface.encodeFunctionData('updateBeaconSetWithBeacons', [beaconIds]);
  return [calldatas];
};
