import { useRef, lazy, Suspense } from 'react';
import { CSSTransition } from 'react-transition-group';
import Jazzicon from 'react-jazzicon';
import styled from 'styled-components';
import { useOnClickOutside } from 'hooks';
import { breakpoints } from 'styles/theme';
import { abbrStr } from 'utils/utils';
import { Button, CopyButton } from 'components/shared';
import { HelpOutlineIcon } from 'components/shared/icons';
import { CloseButton } from 'components/shared/close-button/close-button';
import { SupportLinks } from 'components/layout/header/support-links';
import { useConnectedAccount } from 'hooks/chain-data';
import { useGeneralStore } from 'stores';
import clsx from 'clsx';
import { WalletDetails } from './header/wallet-details';

const ConnectButton = lazy(() => import('components/sign-in/connect-button'));

const sidebarTransitionStyles: Record<string, any> = {
  entering: { right: '-100%', opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { right: '-100%', opacity: 0 },
};

export type MenuState = 'closed' | 'opened-support' | 'opened-wallet';

const MobileMenu = () => {
  const { mobileMenuState, closeSidebar } = useGeneralStore();
  const { isConnected, connectedAddress, connectedAccountName } = useConnectedAccount();

  const outsideClickRef = useRef(null);
  useOnClickOutside(outsideClickRef, closeSidebar);

  // Used to silence the findDOMNode error thrown by CSSTransition
  const nodeRef = useRef(null);

  return (
    <CSSTransition nodeRef={nodeRef} in={mobileMenuState !== 'closed'} timeout={200}>
      {(state) => (
        <MobileMenuContainer
          className={clsx('mobile-menu', mobileMenuState)}
          ref={outsideClickRef}
          style={{ ...sidebarTransitionStyles[state] }}
        >
          <StyledHeader>
            <img src="/images/api3-market-logo-mobile.svg" alt="Api3 Market logo" />
            <CloseButton onClick={closeSidebar} className="close-button" />
            <span className="sr-only">Close mobile menu</span>
          </StyledHeader>

          {mobileMenuState === 'opened-support' && (
            <SupportSection>
              <div className="support-header">
                <HelpOutlineIcon />
                Support
              </div>

              <div className="support-links">
                <SupportLinks onClick={closeSidebar} />
              </div>
            </SupportSection>
          )}

          {mobileMenuState === 'opened-wallet' && (
            <StyledWallet>
              {isConnected && connectedAddress && (
                <>
                  <div className="mobile-menu-account">
                    <Jazzicon
                      diameter={32}
                      seed={parseInt(connectedAddress, 16)}
                      paperStyles={{
                        margin: '4px',
                        minWidth: '32px',
                      }}
                    />
                    <span className="account-address-text">{connectedAccountName || abbrStr(connectedAddress, 5)}</span>
                    <CopyButton copyString={connectedAddress} />
                  </div>
                  <div className="wallet-details">
                    <WalletDetails onDisconnect={closeSidebar} />
                  </div>
                </>
              )}

              {!isConnected && (
                <Suspense
                  fallback={
                    <Button variant="secondary" size="small" fullWidth disabled>
                      Connect Wallet
                    </Button>
                  }
                >
                  <ConnectButton variant="secondary" size="small" fullWidth>
                    Connect Wallet
                  </ConnectButton>
                </Suspense>
              )}
            </StyledWallet>
          )}
        </MobileMenuContainer>
      )}
    </CSSTransition>
  );
};

const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;

  background:
    radial-gradient(
      376.7% 96.63% at 50% -9.17%,
      rgba(45, 55, 163, 0.7) 0%,
      rgba(9, 13, 56, 0.7) 45.09%,
      rgba(5, 8, 36, 0.7) 100%
    ),
    linear-gradient(0deg, #030412 0%, #030412 100%), #d9d9d9;
  background-color: #04071f;
  background-repeat: no-repeat;
  background-size: 100% 3613px;

  z-index: var(--z-index-mobile-menu);
  overflow-x: auto;
  overflow-y: auto;
  transition:
    right 0.2s,
    opacity 0.5s;

  width: 100%;

  @media (min-width: ${breakpoints.xs}px) {
    width: 360px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 6px 8px 16px;
  margin-bottom: 30px;
`;

const SupportSection = styled.div`
  .support-header {
    padding: 16px;
    margin-left: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-base-light);
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .support-links {
    background-color: var(--color-base-blue-ui);
    & > div {
      padding: 0;
      gap: 0;
    }
    a.menu-dropdown-secondary.medium {
      width: 100%;
      border-bottom: 1px solid var(--color-dark-blue-400);
      padding: 24px 0 24px 40px;
      height: 90px;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`;

const StyledWallet = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0 16px 40px 16px;

  .mobile-menu-account {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 0;

    .account-address-text {
      padding-left: 8px;
      padding-right: 16px;
      color: var(--color-gray-300);
      font-size: 18px;
      line-height: 150%;
    }

    > button {
      padding: 0;
      height: 20px;
    }

    svg {
      width: 24px;
      height: 24px;
      color: var(--color-dark-blue-50);
    }
  }

  .wallet-details {
    width: 100%;
    background-color: var(--color-base-blue-ui);

    & > div {
      padding: 0;
      & > * {
        padding: 24px 0 24px 40px;
      }
      & > *:first-child {
        border-bottom: 1px solid var(--color-dark-blue-400);
      }
    }
  }
`;

export default MobileMenu;
