import { Button } from 'components/shared';
import { links } from 'constants/links';
import { usePageParams, useURLWithShowHidden } from 'hooks';
import styled from 'styled-components';

export const CatalogButton = () => {
  const { chainAlias = '' } = usePageParams();
  const { getURL } = useURLWithShowHidden();

  return (
    <StyledButton
      variant="link"
      color="blue"
      className="catalog-button"
      href={getURL(`${links.DATA_FEED_CATALOG}?chain=${chainAlias}`)}
      iconEnd={null}
    >
      View full data feed catalog
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  align-self: center;
`;
