import styled from 'styled-components';
import { Button } from 'components/shared';
import { links } from 'constants/links';

interface Props {
  onClick: () => void;
}
export const SupportLinks = ({ onClick }: Props) => {
  return (
    <Container>
      <Button
        href={links.API3_DISCORD}
        variant="menu-dropdown-secondary"
        size="medium"
        onClick={onClick}
        iconStart={<img src="/images/support-dropdown-icons/dev-support.svg" alt="" />}
      >
        Dev support
      </Button>
      <Button
        href={links.FORM_REQUEST_DATA_FEED}
        variant="menu-dropdown-secondary"
        size="medium"
        onClick={onClick}
        iconStart={<img src="/images/support-dropdown-icons/request-a-new-data-feed.svg" alt="" />}
      >
        Request a new data feed
      </Button>
      <Button
        href={links.FORM_REQUEST_CHAIN}
        variant="menu-dropdown-secondary"
        size="medium"
        onClick={onClick}
        iconStart={<img src="/images/support-dropdown-icons/request-a-new-chain.svg" alt="" />}
      >
        Request a new chain
      </Button>
      <Button
        href={links.FORM_REQUEST_GRANT}
        variant="menu-dropdown-secondary"
        size="medium"
        onClick={onClick}
        iconStart={<img src="/images/support-dropdown-icons/apply-for-gas-grant.svg" alt="" />}
      >
        Apply for gas grant
      </Button>
      <Button
        href={links.FORM_REQUEST_DAPP_ALIAS}
        variant="menu-dropdown-secondary"
        size="medium"
        onClick={onClick}
        iconStart={<img src="/images/support-dropdown-icons/apply-for-dapp-alias.svg" alt="" />}
      >
        Apply for OEV Rewards
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px 24px 40px;
  gap: 16px;
  border-radius: 10px;

  a.menu-dropdown-secondary.medium {
    display: flex;
    padding: 8px 0px;
    align-items: center;
    height: 58px;

    & > img {
      margin-right: 24px;
    }

    & > svg {
      margin-left: 10px;
      transform: none;
      width: 12px;
      height: 12px;
      color: var(--color-dark-blue-50);
    }
  }
`;
