import { ethers } from 'ethers';
import { api3Contracts } from '@api3/dapi-management';
import { go } from '@api3/promise-utils';
import { DEFAULT_PROXY_METADATA } from '../constants/constants';

const {
  AirseekerRegistry__factory: AirseekerRegistryFactory,
  Api3MarketV2__factory: Api3MarketV2Factory,
  computeApi3ReaderProxyV1Address,
  deploymentAddresses,
  DAPPS,
} = api3Contracts;

export const getAirseekerRegistry = async (chainId: string, providerOrSigner: ethers.Provider | ethers.Signer) => {
  const AirseekerRegisterAddress =
    deploymentAddresses.AirseekerRegistry?.[chainId as keyof typeof deploymentAddresses.AirseekerRegistry];
  return AirseekerRegistryFactory.connect(AirseekerRegisterAddress, providerOrSigner);
};

export const getApi3Market = async (chainId: string, providerOrSigner: ethers.Provider | ethers.Signer) => {
  const api3MarketAddress =
    deploymentAddresses.Api3MarketV2?.[chainId as keyof typeof deploymentAddresses.Api3MarketV2];
  return Api3MarketV2Factory.connect(api3MarketAddress, providerOrSigner);
};

export const computeProxyAddress = async (
  chainId: string,
  dapiName: string,
  dappId: bigint,
  provider: ethers.Provider
) => {
  const metadata = DEFAULT_PROXY_METADATA;

  // Compute address deterministically
  const proxyAddress = computeApi3ReaderProxyV1Address(parseInt(chainId, 10), dapiName, dappId, metadata);

  // Check if there's a proxy deployed at the computed address
  const result = await go(() => provider.getCode(proxyAddress));

  // If metadata found on the chain is different from the one from params, it means the proxy is already deployed
  return { proxyAddress, deployed: result.data !== metadata, metadata, ...result };
};

export { DAPPS };
