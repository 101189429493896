import { ComponentProps } from 'react';

export const BadgeCheckIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.39019 2.46119C8.74575 3.01038 7.94448 3.34228 7.10046 3.40963C5.13084 3.56681 3.56681 5.13084 3.40963 7.10046C3.34228 7.94448 3.01038 8.74575 2.46119 9.39019C1.1796 10.8941 1.1796 13.1059 2.46119 14.6098C3.01038 15.2542 3.34228 16.0555 3.40963 16.8995C3.56681 18.8692 5.13084 20.4332 7.10046 20.5904C7.94448 20.6577 8.74575 20.9896 9.39019 21.5388C10.8941 22.8204 13.1059 22.8204 14.6098 21.5388C15.2542 20.9896 16.0555 20.6577 16.8995 20.5904C18.8692 20.4332 20.4332 18.8692 20.5904 16.8995C20.6577 16.0555 20.9896 15.2542 21.5388 14.6098C22.8204 13.1059 22.8204 10.8941 21.5388 9.39019C20.9896 8.74575 20.6577 7.94448 20.5904 7.10046C20.4332 5.13084 18.8692 3.56681 16.8995 3.40963C16.0555 3.34228 15.2542 3.01038 14.6098 2.46119C13.1059 1.1796 10.8941 1.1796 9.39019 2.46119ZM16.2071 10.3738C16.5976 9.98327 16.5976 9.3501 16.2071 8.95958C15.8166 8.56906 15.1834 8.56906 14.7929 8.95958L10.8333 12.9191L9.20709 11.2929C8.81657 10.9024 8.1834 10.9024 7.79288 11.2929C7.40235 11.6834 7.40235 12.3166 7.79288 12.7071L10.1262 15.0405C10.5167 15.431 11.1499 15.431 11.5404 15.0405L16.2071 10.3738Z"
        fill="currentColor"
      />
    </svg>
  );
};
