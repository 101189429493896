// List of external links used in the app
export const links = {
  API3_DISCORD: 'https://discord.gg/api3dao',
  API3_WEBSITE: 'https://api3.org/',
  DATA_FEED_CATALOG: 'https://market-catalog.api3.org/',
  FORM_REQUEST_CHAIN: 'https://api3dao.typeform.com/to/UiUPuZHb',
  FORM_REQUEST_DAPP_ALIAS: 'https://api3dao.typeform.com/to/FHhFIL41',
  FORM_REQUEST_DATA_FEED: 'https://api3dao.typeform.com/to/yT2Gt2fd',
  FORM_REQUEST_GRANT: 'https://api3dao.typeform.com/to/TBTu8bJt',
  PRIVACY_AND_COOKIES: 'https://api3.org/privacy-and-cookies/',
  PRIVACY_POLICY: 'https://api3.org/privacy-policy/',
  DOCS_AIRNODE_VERIFICATION: 'https://docs.api3.org/dapps/integration/#verifying-first-party-sources',
  DOCS_OEV_REWARDS: 'https://docs.api3.org/dapps/oev-rewards/',
  DOCS_DAPPS: 'https://docs.api3.org/dapps/',
  DOCS_PROXY: 'https://docs.api3.org/dapps/integration/contract-integration.html',
  REPORT_A_BUG: 'https://github.com/api3dao/api3-market-issues/issues/',
  SENTRY: 'https://sentry.io/',
  TERMS_AND_CONDITIONS: 'https://api3.org/terms-and-conditions/',
};
