import { ethers } from 'ethers';
import { api3ApiIntegrations } from '@api3/dapi-management';
import AIRNODE_ADDRESSES from './mock-airnode-addresses.json';

export const {
  apisData,
  getDecodedParameters,
  getApiProviderAliases,
  getOisTitlesWithAirnodeAddress,
  deriveDataFeedId,
  deriveEndpointId,
  getSupportedProvidersForDataFeed,
  getAllDataFeeds,
} = api3ApiIntegrations;

export const PROVIDERS = [
  'coingecko',
  'coinpaprika',
  'dxfeed',
  'finage',
  'kaiko',
  'ncfx',
  'nodary',
  'nodary-mock',
  'twelvedata',
];

export const API_URLS = [
  {
    alias: 'coingecko',
    name: 'CoinGecko',
    homepageUrl: 'https://www.coingecko.com',
    verifiedBaseUrl: 'https://api.coingecko.com/api/v3',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'coinpaprika',
    name: 'CoinPaprika',
    homepageUrl: 'https://coinpaprika.com',
    verifiedBaseUrl: 'https://api.coinpaprika.com/v1',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'dxfeed',
    name: 'DXFeed',
    homepageUrl: 'https://www.dxfeed.com',
    verifiedBaseUrl: 'https://www.dxfeed.com',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'finage',
    name: 'Finage',
    homepageUrl: 'https://finage.co.uk',
    verifiedBaseUrl: 'https://api.finage.co.uk',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'iexcloud',
    name: 'IEX Cloud',
    homepageUrl: 'https://iexcloud.io',
    verifiedBaseUrl: 'https://cloud.iexapis.com/v1',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'kaiko',
    name: 'Kaiko',
    homepageUrl: 'https://www.kaiko.com ',
    verifiedBaseUrl: 'https://us.market.api.kaiko.io',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'ncfx',
    name: 'NCFX',
    homepageUrl: 'https://ncfx.com',
    verifiedBaseUrl: 'https://api.ncfx.com',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'nodary',
    name: 'Nodary',
    homepageUrl: 'https://nodary.io',
    verifiedBaseUrl: 'https://api.nodary.io',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'nodary-mock',
    name: 'Nodary Mock',
    homepageUrl: 'https://nodary.io',
    verifiedBaseUrl: 'https://api.nodary.io',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'twelvedata',
    name: 'Twelve Data',
    homepageUrl: 'https://twelvedata.com',
    verifiedBaseUrl: 'https://api.twelvedata.com',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'blastapi-mock',
    name: 'Blast API Mock',
    homepageUrl: 'https://blastapi.com',
    verifiedBaseUrl: 'https://api.blastapi.com',
    signedApiUrl: 'https://signed-api.api3.org',
  },
  {
    alias: 'ankr-mock',
    name: 'Ankr Mock',
    homepageUrl: 'https://ankr.com',
    verifiedBaseUrl: 'https://api.ankr.com',
    signedApiUrl: 'https://signed-api.api3.org',
  },
];

export const getApiUrls = (airnodeAddress: string) => {
  const airnodeIndex = AIRNODE_ADDRESSES.findIndex((a) => a === airnodeAddress);
  return API_URLS[airnodeIndex];
};

export const getAirnodeAddressByAlias = (provider: string) => {
  const providerIndex = PROVIDERS.findIndex((p) => p === provider);
  return AIRNODE_ADDRESSES[providerIndex];
};

export function getOisTitleByFeedNameAndAirnodeAddress(_feedName: string, _airnodeAddress: string) {
  return 'TwelveData'; // Not really used
}

export function deriveTemplateId(inputs: { feedName: string; oisTitle?: string; airnodeAddress?: string }) {
  return ethers.encodeBytes32String(`${inputs.feedName.substring(0, 16)}-${inputs.airnodeAddress?.substring(0, 8)}`);
}
